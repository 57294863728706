import React, {useState} from 'react';
import classes from './Dropdown.module.css';
const Dropdown = props => {
    const [toggle, setToggle] = useState(false);

    let dropdownToggle = [classes.OptionContainer];
    if (toggle) {
        dropdownToggle = [classes.OptionContainer, classes.Active]
    }

    const toggleHandler = () =>{
        setToggle(!toggle);
    }
    const selectHandler = (value, name) =>{
        
        setToggle(!toggle);
        props.selected(value, name, props.identifier)
    }
    return (
        <div className={classes.SelectBox}>
            <div className={dropdownToggle.join(' ')}>

                {props.data.map(item => (
                    <div key={item[props.myId]} className={classes.option} onClick={()=>{selectHandler(item[props.myId], item.name)}} >
                        <input type='radio' className={classes.radio} id={item.name} name={item.name} /> {item.name}
                    </div>
                ))}

            </div>
            <div className={classes.Selected} onClick={toggleHandler}>
                {props.value.name}
            </div>
        </div>
    );
}

export default Dropdown;