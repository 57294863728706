import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classes from './StatsScreen.module.css';
import SquareCard from '../../components/UI/Card/SquareCard';
import Loader from '../../components/UI/loader/Loader';
import { getBusinessTotals, getBusinessByFieldman, getBusinessTenDays } from '../../helpers/RequestServices';

const StatsScreen = () => {
    const [totals, setTotals] = useState();
    const [totalsFieldmans, setTotalsFieldmans] = useState();
    const [totalsTenDays, setTotalsTenDays] = useState();
    const [loading, setLoading] = useState(true);
    const programId = useSelector(state => state.authStore.programId)

    useEffect(() => {
        Promise.all([getBusinessTotals(programId), getBusinessByFieldman(programId), getBusinessTenDays(programId)]).then(res => {
            console.log(res[0])
            console.log(res[1])

            console.log(res[2])

            setTotals(res[0]);
           
            setTotalsFieldmans(res[1]);
            setTotalsTenDays(res[2]);
            setLoading(false);
        }).catch(err => {
            console.log(err)
            setLoading(false);

        })
    }, []);

    return (
        <div className={classes.Screen}>
            {loading ? <Loader /> :
                <>
                    <div className={classes.HeadContainer}>


                        <div className={classes.CardHolder}>
                            <SquareCard Bordered='Purple'>
                                <div className={classes.CardContent}>
                                    <div className={[classes.Icon, classes.Purple].join(' ')}>
                                        <i className='fa fa-users'></i>
                                    </div>
                                    <div className={classes.CardText}>
                                        <h1 className={classes.HeadTitle}>{totals?.orders ? totals.orders : '-'}</h1>
                                        <p className={classes.TextDetail}>Orders this season</p>
                                    </div>

                                </div>
                            </SquareCard>
                        </div>


                        <div className={classes.CardHolder}>
                            <SquareCard Bordered='Green'>
                                <div className={classes.CardContent}>
                                    <div className={[classes.Icon, classes.Green].join(' ')}>
                                        <i className='fa fa-paper-plane'></i>
                                    </div>
                                    <div className={classes.CardText}>
                                        <h1 className={classes.HeadTitle}>{totalsTenDays?.total_manifolds ? totalsTenDays.total_manifolds : '-'}</h1>
                                        <p className={classes.TextDetail}>Manifolds filled in last 10 days</p>
                                    </div>

                                </div>
                            </SquareCard>
                        </div>
                        <div className={classes.CardHolder}>
                            <SquareCard Bordered='Bordered'>
                                <div className={classes.CardContent}>
                                    <div className={[classes.Icon, classes.Orange].join(' ')}>
                                        <i className='fas fa-seedling'></i>
                                    </div>
                                    <div className={classes.CardText}>
                                        <h1 className={classes.HeadTitle}>{totals?.manifolds ? totals.manifolds : '-'}</h1>
                                        <p className={classes.TextDetail}>Manifolds this season</p>
                                    </div>
                                </div>
                            </SquareCard>
                        </div>
                    </div>
                    <div className={classes.ContentContainer}>
                        <h3 className={classes.Title}>Totals this season</h3>
                        <div className={classes.Summary}>
                            <div className={classes.tableContainer}>
                                <table className={classes.table}>
                                    <tbody>
                                        <tr>
                                            <td>Orders</td>
                                            <td>{totals?.orders ? totals.orders : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Growers</td>
                                            <td>{totals?.growers ? totals.growers : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Fields</td>
                                            <td>{totals?.fields ? totals.fields : '-'}</td>
                                        </tr>
                                        <tr>
                                            <td>Manifolds</td>
                                            <td>{totals?.manifolds ? totals.manifolds : '-'}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className={classes.CardHolder}>
                                <SquareCard Bordered='Bordered'>
                                    <div className={classes.CardContent}>
                                        <div className={[classes.Icon, classes.Orange].join(' ')}>
                                            <i className='fas fa-trophy'></i>
                                        </div>
                                        <div className={classes.CardText}>
                                            <p className={classes.TextDetail}><span className={classes.BoldText}>{totalsFieldmans.length ? totalsFieldmans[0].name : 'no one'}</span> is the most active fieldman with <span className={classes.BoldText}>{totalsFieldmans.length ? totalsFieldmans[0].manifolds: '0'} </span>manifolds this season</p>
                                        </div>
                                    </div>
                                </SquareCard>
                            </div>

                        </div>
                        <h3 className={classes.Title}>Activity by fieldman</h3>

                        <SquareCard >
                            <div className={classes.CardContentFieldman}>
                                <div className={classes.HeadFieldmanTable}>
                                    <div className={classes.HeadItem}>Fieldman</div>
                                    <div className={classes.HeadItem}>Orders</div>
                                    <div className={classes.HeadItem}>Growers</div>
                                    <div className={classes.HeadItem}>Fields</div>
                                    <div className={classes.HeadItem}>Manifolds</div>
                                    <div className={classes.HeadItem}>Manifolds last 10 days</div>
                                </div>

                                {totalsFieldmans.length > 0 && totalsFieldmans.map((item, index) => <div key={index} className={classes.RowFieldmanTable}>
                                    <div className={classes.ContentItem}>{item.name}</div>
                                    <div className={classes.ContentItem}>{item.orders}</div>
                                    <div className={classes.ContentItem}>{item.growers}</div>
                                    <div className={classes.ContentItem}>{item.fields}</div>
                                    <div className={classes.ContentItem}>{item.manifolds}</div>
                                    <div className={classes.ContentItem}>{totalsTenDays.fieldmans_ten[item.name]?totalsTenDays.fieldmans_ten[item.name] :'-'}</div>
                                </div>)}
                            </div>
                        </SquareCard>


                    </div>
                </>
            }

        </div>)

}

export default StatsScreen;