import { LOGIN ,LOGOUT} from './auth-actions';
const initialState = {
    authenticated: false,
    token: null,
    refreshToken: null,
    expirationDate: null,
    role: "",
    programId: 6
};

export default (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                authenticated: true,
                token: action.token,
                expirationDate: action.expirationDate,
                role: action.role,
                programId: action.programId
            };
        case LOGOUT:
            return initialState;

        default:
            return state;
    }
};