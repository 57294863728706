import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import classes from "./HomeScreen.module.css";
import SquareCard from "../../components/UI/Card/SquareCard";
import Button from "../../components/UI/Button/Button";
import FloatingButton from "../../components/UI/Button/FloatingButton";
import Loader from "../../components/UI/loader/Loader";
import Dropdown from "../../components/UI/Dropdown/Dropdown";
import * as RequestServices from "../../helpers/RequestServices";
import {
  setOrdersFormatted,
  setSeasonAction,
} from "store/orders/orders-actions";

const HomeScreen = (props) => {
  const dispatch = useDispatch();
  const season = useSelector((state) => state.ordersStore.season);
  const programId = useSelector((state) => state.authStore.programId);
  const [isLoading, setIsLoading] = useState(false);
  const [clientRequest, setClientRequest] = useState([]);
  const [clientRequestFiltered, setClientRequestFiltered] = useState([]);
  const [clients, setClients] = useState([]);
  const [species, setSpecies] = useState([]);
  const [seasons, setSeasons] = useState([]);

  const history = useHistory();
  //UI
  const [clientSelected, setClientSelected] = useState({
    value: -1,
    name: "Select a client",
  });
  const [especiesSelected, setEspeciesSelected] = useState({
    value: -1,
    name: "Select a especie",
  });
  const [seasonSelected, setSeasonSelected] = useState({
    value: 4,
    name: "2023-2024",
  });

  const userRole = useSelector((state) => state.authStore.role);
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      RequestServices.getClientRequest(seasonSelected, programId),
      RequestServices.getClients(programId),
      RequestServices.getSpecies(programId),
      RequestServices.getGrowers(),
      RequestServices.getFieldGrowers(),
      RequestServices.getSeasons(),
    ]).then((res) => {
      // let phRequests = res[0].filter(item=>item.program=='PH')
      // console.log(phRequests)
      const requestFormated = res[0].map((req) => {
        let currentField = {
          ...req,
          growers: [],
        };
        let currentGrowers = res[3].filter(
          (gr) => gr.request_id === req.request_id
        );
        let growersAndField = currentGrowers.map((item) => {
          let fieldsFiltered = res[4].filter(
            (field) => field.grower_id === item.grower_id
          );
          return {
            ...item,
            fields: fieldsFiltered,
          };
        });
        if (growersAndField) {
          currentField["growers"] = growersAndField;
        }
        return currentField;
      });

      setClientRequest(requestFormated);
      dispatch(setOrdersFormatted(requestFormated));
      setClientRequestFiltered(requestFormated);

      setClients(res[1]);
      setSpecies(res[2]);
      setSeasons(res[5]);

      setIsLoading(false);
    });
  }, [seasonSelected]);

  const navigationHandler = (cr, type) => {
    history.push({ pathname: "/detailclient", state: { cr: cr, type: type } });
  };

  const goToCreate = () => {
    history.push({ pathname: "/createorder", mode: "create" });
  };
  const goToEdit = (cr) => {
    history.push({ pathname: "/createorder", mode: "edit", cr: cr });
  };

  const filterRequest = (clientId) => {
    const filtered = clientRequest.filter((cr) => cr.client_id === clientId);
    setClientRequestFiltered(filtered);
    setEspeciesSelected({ value: -1, name: "Select a especie" });
  };
  const filterRequestByEspecies = (especiesId) => {
    const filtered = clientRequest.filter(
      (cr) =>
        cr.specie_id === especiesId && cr.client_id === clientSelected.value
    );
    setClientRequestFiltered(filtered);
  };

  const selectClientHandler = (value, name, id) => {
    setClientSelected({ value: value, name: name });
    filterRequest(value);
  };
  const selectEspeciesHandler = (value, name, id) => {
    setEspeciesSelected({ value: value, name: name });
    filterRequestByEspecies(value);
  };

  const selectSeasonHandler = (value, name, id) => {
    setSeasonSelected({ value: value, name: name });
    dispatch(setSeasonAction({ value: value, name: name }));
    // filterRequestByEspecies(value);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className={classes.CreateReportScreenContainer}>
      <div className={classes.SelectsContainer}>
        <Dropdown
          data={clients}
          selected={selectClientHandler}
          value={clientSelected}
          myId="client_id"
          identifier="client"
        />

        <Dropdown
          data={species}
          selected={selectEspeciesHandler}
          value={especiesSelected}
          myId="specie_id"
          identifier="specie"
        />
      </div>
      <div className={classes.SelectsContainer}>
        <Dropdown
          data={seasons}
          selected={selectSeasonHandler}
          value={seasonSelected}
          myId="season_id"
          identifier="season"
        />
      </div>

      {clientRequestFiltered.length > 0 ? (
        <div className={classes.FieldsFilteredContainer}>
          <h2 className={classes.ContainerTitle}>
            Orders {season?.name ? season.name : ""}
          </h2>
          {clientRequestFiltered.map((cr) => (
            <SquareCard key={cr.request_id} Bordered="Bordered">
              <div className={classes.CardContainer}>
                <div className={classes.DetailContainer}>
                  <div className={classes.Details}>
                    <h4 className={classes.CardTitle}>{cr.client}</h4>

                    <p className={classes.TextItem}>
                      <strong></strong>
                      {cr.specie}
                    </p>
                    <p className={classes.TextItem}>
                      <strong></strong>
                      {cr.variety}
                    </p>
                  </div>
                  <div className={classes.Details}>
                    <p className={classes.TextItem}>
                      <strong>Ratio: </strong>
                      {cr.ratio}
                    </p>
                    <p className={classes.TextItem}>
                      <strong>Target kg: </strong>
                      {cr.target_kg}
                    </p>

                    <p className={classes.TextItem}>
                      <strong>Base: </strong>
                      {cr.base}
                    </p>
                  </div>
                  <div className={classes.Details}>
                    <p className={classes.TextItem}>
                      <strong>Required Surface: </strong>
                      {cr.required_surface}
                    </p>
                    <p className={classes.TextItem}>
                      <strong>Fieldman: </strong>
                      {cr.fieldman_name ? cr.fieldman_name : ""}
                    </p>
                  </div>
                  <div className={classes.Details}>
                    {cr.growers.map((crg) => (
                      <p className={classes.TextItem} key={crg.grower_id}>
                        <strong>Grower: </strong>
                        {crg.name}
                      </p>
                    ))}
                  </div>
                  <div className={classes.ButtonHolder}>
                    <Button
                      btnType="Orange"
                      clicked={() => navigationHandler(cr, "Client")}
                    >
                      Client Report
                    </Button>
                    <Button
                      btnType="Blue"
                      clicked={() => navigationHandler(cr, "Normal")}
                    >
                      Manifold Report
                    </Button>
                    <Button
                      btnType="Blue"
                      clicked={() =>
                        window.open(
                          `http://zeed-front.s3-website-us-east-1.amazonaws.com/inspectionReport/${cr.request_id}`
                        )
                      }
                    >
                      Inspection Report
                    </Button>
                    <Button btnType="Save" clicked={() => goToEdit(cr)}>
                      Edit Order
                    </Button>
                  </div>
                </div>
              </div>
            </SquareCard>
          ))}
        </div>
      ) : null}
      {userRole === "admin" && (
        <FloatingButton clicked={goToCreate} btnType="Orange">
          +
        </FloatingButton>
      )}
    </div>
  );
};

export default HomeScreen;
