import React from 'react';

import Aux from '../../../hoc/MyAux';
import Backdrop from '../Backdrop/Backdrop';
import classes from './Modal.module.css';
import Button from 'components/UI/Button/Button';
const Modal = props => {




    return (<Aux>
        <Backdrop show={props.show} clicked={props.modalClosed} />
        <div className={classes.Modal}
            style={{
                transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: props.show ? '1' : '0'
            }}

        >
            <p className={classes.title}>Delete data</p>
            <p className={classes.infoText}>Are you sure? You can't undo this action afterwards.</p>
            <div className={classes.buttonContainer}>
                <Button btnType='Soft' clicked={props.modalClosed}>Cancel</Button>
                <Button btnType='Danger' clicked={props.deleteAction}>Delete</Button>
            </div>

        </div>
    </Aux>);

}

export default Modal;