import React from 'react';

import classes from './SquareCard.module.css';

const Card= props =>{
    if(props)
    return(
        <div className={[classes.SquareCard, classes[props.Bordered]].join(' ')} >
            {props.children}
        </div>
    );
}

export default Card;