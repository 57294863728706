import Report from "../models/Report";
import Field from "../models/Field";
import {
  GET_FIELDS,
  GET_REPORTS,
  ADD_CLIENT,
  FILTER_CLIENT,
  GET_CLIENTS,
  GET_SPECIES,
  ADD_SPECIE,
  GET_RATIOS,
  ADD_RATIO,
  GET_VARIETIES,
  ADD_VARIETY,
  DELETE_CLIENT,
  PUT_CLIENT,
  DELETE_SPECIE,
  PUT_SPECIE,
  DELETE_RATIO,
  PUT_RATIO,
  DELETE_VARIETY,
  PUT_VARIETY
} from "./actions";

const initalState = {
  fields: [],
  reports: [],
  clients: [],
  species: [],
  ratios: [],
  varieties: [],
  filter: "",
};

export default (state = initalState, action) => {
  
  switch (action.type) {
    case GET_FIELDS:
      return {
        ...state,
        fields: action.loadFields.map(
          (field) =>
            new Field(
              field.id.toString(),
              field.name,
              field.locationName,
              field.clientId,
              field.jobNumber,
              field.growerId,
              field.specieId,
              field.varietyId,
              field.ratioId,
              field.code,
              field.stimatedSup,
              field.realSup,
              field.baseClient,
              field.baseGrower,
              field.fieldmanId,
              field.lat,
              field.lng
            )
        ),
      };
    case GET_REPORTS:
      return {
        ...state,
        reports: action.reports.map(
          (report) =>
            new Report(
              report.reportId,
              report.fieldId,
              report.reportType,
              report.active,
              report.female,
              report.male1,
              report.male2,
              report.male3,
              report.imageLg1,
              report.imageLg2,
              report.imageLg3,
              report.imageSm1,
              report.imageSm2,
              report.imageSm3
            )
        ),
      };
    case ADD_CLIENT:
      return {
        ...state,
        clients: state.clients.concat({
          client_id: action.id,
          name: action.name,
        }),
      };
    case FILTER_CLIENT:
      return {
        ...state,
        filter: action.value,
      };
    case DELETE_CLIENT:
      return {
        ...state,
        clients: action.clients,
      };
    case PUT_CLIENT:
      return {
        ...state,
        clients: action.clients,
      };

    case GET_CLIENTS:
      return {
        ...state,
        clients: action.clients,
      };
    case GET_SPECIES:
      return {
        ...state,
        species: action.species,
      };
    case ADD_SPECIE:
 
      return {
        ...state,
        species: state.species.concat({
          specie_id: action.id,
          name: action.name,
        }),
      };
    case DELETE_SPECIE:
      return {
        ...state,
        species: action.species,
      };
    case PUT_SPECIE:
      return {
        ...state,
        species: action.species,
      };
    case GET_RATIOS:
      return {
        ...state,
        ratios: action.ratios,
      };
    case ADD_RATIO:
      return {
        ...state,
        ratios: state.ratios.concat({ ratio_id: action.id, name: action.name }),
      };
    case DELETE_RATIO:
      return {
        ...state,
        ratios: action.ratios,
      };
    case PUT_RATIO:
      return {
        ...state,
        ratios: action.ratios,
      };
    case GET_VARIETIES:
      return {
        ...state,
        varieties: action.varieties,
      };
    case ADD_VARIETY:
      return {
        ...state,
        varieties: state.varieties.concat({
          variety_id: action.id,
          name: action.name,
        }),
      };
    case DELETE_VARIETY:
      return {
        ...state,
        varieties: action.varieties,
      };
    case PUT_VARIETY:
      return {
        ...state,
        varieties: action.varieties,
      };
  }

  return state;
};
