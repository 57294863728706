import axios from '../../axios';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const authenticate = (token, expirationDate, role, programId) => {
    return dispatch => {

        dispatch({ type: LOGIN, token: token, expirationDate: expirationDate, role: role, programId: programId });
    };
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(tryGetToken());
        }, expirationTime * 1000);
    };
};

export const tryGetToken =  () =>{
    return async (dispatch, getState) => {
        const token = localStorage.getItem('refreshToken');
        if (!token) {
            dispatch(logout());
        } else {
            try {
                const response = await axios.post('https://www.zeedgo.xyz/gettoken', {
                'userId': 2
            }, {
                headers: {
                    'x-auth': token
                }
            })
            const resData = await response.data;
            const expirationDate = new Date(
                new Date().getTime() + 3600000 //60*60*1000
            );
            saveDataToStorage(resData.token, expirationDate);
            dispatch(authenticate(resData.token, expirationDate, getState().authStore.role, getState().authStore.programId));
            dispatch(checkAuthTimeout(30));



            } catch (error) {
                dispatch(logout())
            }

           
                
            
             
        }
    };
}
export const logIn = (mail, password) => {
    return async dispatch => {

        const response = await axios.post('https://www.zeedgo.xyz/login', {
            'mail': mail,
            'password': password
        })
        const resData = await response.data;
        
        if (response.status !==200) {
            
            
            throw new Error('BAD')
        } else {
            
            const expirationDate = new Date(
                new Date().getTime() + 3600000 //60*60*1000
            );
            localStorage.setItem('refreshToken', resData.refreshToken);
            saveDataToStorage(resData.token, expirationDate);
            saveUserDataToStorage(resData.role, resData.program_id);
            
            dispatch(authenticate(resData.token, expirationDate, resData.role, resData.program_id));
            dispatch(checkAuthTimeout(30));

        }

    }
}

const saveDataToStorage = (token, expirationDate) => {
    localStorage.setItem('token', token);
    localStorage.setItem('expirationDate', expirationDate.toISOString());
    
};

const saveUserDataToStorage = (role, program_id) => {
    localStorage.setItem('role', role);
    localStorage.setItem('programId', program_id);
    
};

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('expirationDate');
   
    return dispatch => {

        dispatch({ type: LOGOUT});
    };
};

export const authCheckState = () => {
    return (dispatch, getState) => {
        const token = localStorage.getItem('token');
        const expirationDate = localStorage.getItem('expirationDate');
        const role = localStorage.getItem('role');
        const programId = localStorage.getItem('programId');
        
        if( token && expirationDate && role && programId){
            dispatch(authenticate(token, expirationDate, role, programId));
        }else{
            dispatch(logout());
        }

        // if (!token) {
        //     dispatch(logout());
        // } else {
        //     const expirationDate = new Date(localStorage.getItem('expirationDate'));
        //     if (expirationDate <= new Date()) {
        //         dispatch(logout());
        //     } else {
                
        //         dispatch(authenticate(token, expirationDate, getState().authStore.role));
        //         dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
        //     }   
        // }
    };
};