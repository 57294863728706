import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import classes from "./ReportScreen.module.css";
import pptxgen from "pptxgenjs";
import headImg from "./resources/head_piga.png";
import footImg from "./resources/foot_piga.png";
import {
  getAllReports,
  getLastManifoldPictures,
  getClients,
  getSpecies,
} from "../../helpers/RequestServices";
import Loader from "../../components/UI/loader/Loader";
import SquareCard from "../../components/UI/Card/SquareCard";
import TableBig from "../../components/UI/TableBig/TableBig";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Modal from "components/UI/Modal/Modal";
import PDFPrevieweManifold from "../PDFPreviewer/PDFPrevieweManifold";
import Dropdown from "components/UI/Dropdown/Dropdown";
import Button from "components/UI/Button/Button";
const ReportScreen = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [joinedData, setJoinedData] = useState();
  const [clients, setClients] = useState([]);
  const [species, setSpecies] = useState([]);
  const [pdfData, setPdfData] = useState();
  const [selected, setSelected] = useState();
  const orders = useSelector((state) => state.ordersStore.ordersFormatted);
  const season = useSelector((state) => state.ordersStore.season);
  console.log(season);
  const [ordersFiltered, setOrdersFiltered] = useState();
  const [clientSelected, setClientSelected] = useState({
    value: -1,
    name: "Select a client",
  });
  const [especiesSelected, setEspeciesSelected] = useState({
    value: -1,
    name: "Select a especie",
  });
  const [colOne, setColOne] = useState({
    value: -1,
    name: "Select column one",
  });
  const [colTwo, setColTwo] = useState({
    value: -1,
    name: "Select column two",
  });
  const programId = useSelector((state) => state.authStore.programId);

  const [extraChecked, setExtraChecked] = useState(false);
  const cols = [
    { name: "surface", value: "surface" },
    { name: "plants/meter", value: "plants" },
    { name: "sowing", value: "sowing" },
    { name: "transplanting beginning", value: "transplanting" },
    { name: "transplanting finish", value: "transplanting_f" },
    { name: "commercial", value: "commercial" },
    { name: "cut", value: "cut" },
    { name: "bolting", value: "bolting" },
    { name: "flowering 1%", value: "flowering_1" },
    { name: "flowering 5%", value: "flowering_5" },
    { name: "flowering -1%", value: "flowering_0" },
    { name: "nicking", value: "nicking" },
    { name: "staking", value: "staking" },
    { name: "bee", value: "bee" },
    { name: "male elimination", value: "male_elimination" },
    { name: "last irrigation", value: "last_irrigation" },
    { name: "harvest", value: "harvest" },
    { name: "dessicant", value: "dessicant" },
  ];
  const myKeys = {
    surface: {
      cols: ["surface_e", "surface_r"],

      head: ["Estimated Surface", "Real Surface"],
    },
    plants: {
      cols: ["plants_real", "plants_r_m1", "plants_r_m2"],

      head: ["Plants/M Female", "Plants/M Male 1", "Plants/M Male 2"],
    },
    sowing: {
      cols: [
        "sowing_female",
        "sowing_male_1",
        "sowing_male_2",
        "sowing_male_3",
      ],

      head: [
        "sowing female",
        "sowing male 1",
        "sowing male 2",
        "sowing male 3",
      ],
    },
    transplanting: {
      cols: [
        "transplanting_female",
        "transplanting_male_1",
        "transplanting_male_2",
        "transplanting_male_3",
      ],
      head: [
        "transplanting female",
        "transplanting male 1",
        "transplanting male 2",
        "transplanting male 3",
      ],
    },
    transplanting_f: {
      cols: [
        "transplanting_f_female",
        "transplanting_f_male_1",
        "transplanting_f_male_2",
        "transplanting_f_male_3",
      ],
      head: [
        "transplanting finish female",
        "transplanting finish male_1",
        "transplanting finish male_2",
        "transplanting finish male_3",
      ],
    },
    commercial: {
      cols: [
        "commercial_female",
        "commercial_male_1",
        "commercial_male_2",
        "commercial_male_3",
      ],
      head: [
        "commercial female",
        "commercial male 1",
        "commercial male 2",
        "commercial male 3",
      ],
    },
    cut: {
      cols: ["cut_female", "cut_male_1", "cut_male_2", "cut_male_3"],
      head: ["cut female", "cut male 1", "cut male 2", "cut male 3"],
    },
    bolting: {
      cols: [
        "bolting_female",
        "bolting_male_1",
        "bolting_male_2",
        "bolting_male_3",
      ],
      head: [
        "bolting female",
        "bolting male 1",
        "bolting male 2",
        "bolting male 3",
      ],
    },
    flowering_1: {
      cols: [
        "flowering_1_female",
        "flowering_1_male_1",
        "flowering_1_male_2",
        "flowering_1_male_3",
      ],
      head: [
        "flowering 1 female",
        "flowering 1 male 1",
        "flowering 1 male 2",
        "flowering 1 male 3",
      ],
    },
    flowering_5: {
      cols: [
        "flowering_5_female",
        "flowering_5_male_1",
        "flowering_5_male_2",
        "flowering_5_male_3",
      ],
      head: [
        "flowering 5 female",
        "flowering 5 male 1",
        "flowering 5 male 2",
        "flowering 5 male 3",
      ],
    },
    flowering_0: {
      cols: [
        "flowering_0_female",
        "flowering_0_male_1",
        "flowering_0_male_2",
        "flowering_0_male_3",
      ],
      head: [
        "flowering 0 female",
        "flowering 0 male 1",
        "flowering 0 male 2",
        "flowering 0 male 3",
      ],
    },

    nicking: {
      cols: [
        "nicking_female",
        "nicking_male_1",
        "nicking_male_2",
        "nicking_male_3",
      ],
      head: [
        "nicking female",
        "nicking male 1",
        "nicking male 2",
        "nicking male 3",
      ],
    },
    staking: { cols: ["staking"], head: ["staking"] },
    male_elimination: {
      cols: ["male_elimination"],
      head: ["male elimination"],
    },
    last_irrigation: { cols: ["last_irrigation"], head: ["last irrigation"] },
    harvest: { cols: ["harvest"], head: ["harvest"] },
    dessicant: { cols: ["desiccant"], head: ["desiccant"] },
    bee: { cols: ["bee"], head: ["bee"] },
  };

  // let dataChartRadar = [
  //     {
  //         name: "Region 1",
  //         labels: ["May", "June", "July", "August", "September"],
  //         values: [26, 53, 100, 75, 41],
  //     },
  // ];
  //slide.addChart(pptx.ChartType.radar, dataChartRadar, { x: 0.36, y: 2.25, w: 4.0, h: 4.0, radarStyle: "standard" });

  //slide.addShape(pptx.ShapeType.rect, { x: 4.36, y: 2.36, w: 5, h: 2.5, fill: pptx.SchemeColor.background2 });

  //slide.addText("React Demo!", { x: 1, y: 1, w: "80%", h: 1, fontSize: 36, fill: "eeeeee", align: "center" });
  // slide.addText("React Demo!", {
  //     x: 1,
  //     y: 0.5,
  //     w: "80%",
  //     h: 1,
  //     fontSize: 36,
  //     align: 'center',
  //     fill: { color:'D3E3F3' },
  //     color: '008899',
  // });

  useEffect(() => {
    Promise.all([
      getAllReports(),
      getLastManifoldPictures(),
      getClients(programId),
      getSpecies(programId),
    ])
      .then((res) => {
        let formattedData = [...orders];
        let pdfData = {};
        formattedData.forEach((order) => {
          if (!pdfData[`${order.client_id}-${order.specie_id}`]) {
            pdfData[`${order.client_id}-${order.specie_id}`] = {
              data: { client: order.client, specie: order.specie },
              varieties: [order.variety],
            };
          }
          if (
            !pdfData[`${order.client_id}-${order.specie_id}`][order.variety]
          ) {
            pdfData[`${order.client_id}-${order.specie_id}`][order.variety] = {
              data: { variety: order.variety },
              manifolds: [],
            };
          }

          pdfData[`${order.client_id}-${order.specie_id}`]["varieties"].indexOf(
            order.variety
          ) === -1
            ? pdfData[`${order.client_id}-${order.specie_id}`][
                "varieties"
              ].push(order.variety)
            : console.log("");

          order.growers.forEach((grower) => {
            grower.fields.forEach((field) => {
              let filteredReports = res[0].filter(
                (report) => report.field_id === field.field_id
              );
              let manifold = res[1].filter(
                (mani) => mani.field_id === field.field_id
              );

              field["reports"] = filteredReports;
              if (manifold.length > 0) {
                pdfData[`${order.client_id}-${order.specie_id}`][order.variety][
                  "manifolds"
                ].push({ ...manifold[0], grower_name: grower.name });
              }
            });
          });
        });

        setClients(res[2]);
        setSpecies(res[3]);
        setPdfData(pdfData);
        // setSelected(pdfData['12-5'])
        // console.log(pdfData)

        setJoinedData(formattedData);
        setOrdersFiltered(formattedData);

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [orders]);

  const selectClientHandler = (value, name, id) => {
    setClientSelected({ value: value, name: name });
    setOrdersFiltered(joinedData.filter((order) => order.client_id === value));
    setEspeciesSelected({ value: -1, name: "Select a especie" });
    setSelected(null);
    let clientIds = Object.keys(pdfData).filter((v) =>
      v.startsWith(`${value}-`)
    );
    if (clientIds.length > 0) {
      let ordersTemp = [];
      clientIds.forEach((item) => {
        ordersTemp.push(pdfData[item]);
      });

      setSelected(ordersTemp);
    }
  };
  const selectEspeciesHandler = (value, name, id) => {
    setEspeciesSelected({ value: value, name: name });
    setOrdersFiltered(
      ordersFiltered.filter((order) => order.specie_id === value)
    );

    if (pdfData[`${clientSelected.value}-${value}`])
      setSelected([pdfData[`${clientSelected.value}-${value}`]]);
  };
  const selectCols = (value, name, id) => {
    switch (id) {
      case "col_1":
        setColOne({ value: value, name: name });
        break;
      case "col_2":
        setColTwo({ value: value, name: name });

        break;
      default:
        break;
    }
  };

  // function loadImage() {
  //     const imgSrc = 'https://www.zeedgo.xyz/myfile/90/manifolds/SM_1640262322806_F3055E1E-5C60-44C8-B6C0-5B19875E06CC.jpg'
  //     const img = new Image();
  //     img.src = imgSrc;
  //     img.onload = function() {
  //     //   document.body.appendChild(img); // You can get the image ratio without inserting the image in body
  //       alert('Image ratio' +' w ' +img.width + ' h '+img.height);
  //     }
  //   }

  const pptGenerator = () => {
    setPdfLoading(true);
    let pptx = new pptxgen();
    let slide = pptx.addSlide();
    slide.addImage({ path: headImg, x: "0%", y: "0%", w: "100%", h: "10%" });
    slide.addImage({ path: footImg, x: "0%", y: "90%", w: "100%", h: "10%" });

    slide.addText(`${selected[0].data.client} Report`, {
      x: "5%",
      y: "15%",
      w: "90%",
      h: 0.33,
      fontSize: 20,
      align: "left",

      color: "0b7751", // pptx.SchemeColor.accent3,
    });
    slide.addText("2022 - 2023", {
      x: "5%",
      y: "20%",
      w: "90%",
      h: 0.33,
      fontSize: 14,
      align: "left",

      color: "0b7751", // pptx.SchemeColor.accent3,
    });
    let posY = 24;
    selected.forEach((item) => {
      slide.addText(item.data.specie, {
        x: "5%",
        y: `${posY}%`,
        w: "90%",
        h: 0.33,
        fontSize: 14,
        align: "left",

        color: "A1A1A1", // pptx.SchemeColor.accent3,
      });
      posY = posY + 5;
    });

    slide = pptx.addSlide();
    let rows = [];
    let head = [
      "Variety",
      "Ratio",
      "Base",
      "Grower",
      "Location",
      "Estimation",
      ...myKeys[colOne.value].head,
      ...myKeys[colTwo.value].head,
    ];
    head = head.map((item) => ({
      text: item,
      options: { color: "FFFFFF", fill: "005C97" },
    }));
    // Row One: cells will be formatted according to any options provided to `addTable()`
    rows.push(head);

    // Row Two: set/override formatting for each cell
    // rows.push([
    //     { text: "1st", options: { color: "ff0000" } },
    //     { text: "2nd", options: { color: "00ff00" } },
    //     { text: "3rd", options: { color: "0000ff" } },
    // ]);
    let myRow = [];
    ordersFiltered.forEach((item) => {
      item.growers.forEach((grower) => {
        if (grower.fields && grower.fields.length > 0) {
          grower.fields.forEach((field) => {
            let colOneRow = myKeys[colOne.value].cols.map((itemCol) =>
              field.reports[0][itemCol]
                ? typeof field.reports[0][itemCol] === "string"
                  ? field.reports[0][itemCol].replace("T00:00:00.000Z", "")
                  : field.reports[0][itemCol]
                : " - "
            );
            let colOneTwo = myKeys[colTwo.value].cols.map((itemCol) =>
              field.reports[0][itemCol]
                ? typeof field.reports[0][itemCol] === "string"
                  ? field.reports[0][itemCol].replace("T00:00:00.000Z", "")
                  : field.reports[0][itemCol]
                : " - "
            );
            myRow = [
              item.variety,
              item.ratio,
              item.base,
              grower.name,
              grower.location,
              "",
              ...colOneRow,
              ...colOneTwo,
            ];
            rows.push(myRow);
          });
        }
      });
    });

    slide.addTable(rows, {
      x: "1%",
      y: "2%",
      w: "98%",
      color: "363636",
      autoPage: true,
      fontSize: 10,
      border: { pt: "1", color: "000000", type: "solid" },
    });

    // TABLE 2: Multi-row table
    // - each row's array element is an array of cells
    // let rows = [["A1", "B1", "C1"]];
    // slide.addTable(rows, { align: "left", fontFace: "Arial" });

    // TABLE 3: Formatting at a cell level
    // - use this to selectively override the table's cell options
    // let rows = [
    //     [
    //         { text: "Top Lft", options: { align: "left", fontFace: "Arial" } },
    //         { text: "Top Ctr", options: { align: "center", fontFace: "Verdana" } },
    //         { text: "Top Rgt", options: { align: "right", fontFace: "Courier" } },
    //     ],
    // ];
    // slide.addTable(rows, { w: 9, rowH: 1, align: "left", fontFace: "Arial" });

    selected.forEach((item) => {
      item.varieties.forEach((variety) => {
        if (item[variety].manifolds.length > 0) {
          slide = pptx.addSlide();
          slide.addImage({
            path: headImg,
            x: "0%",
            y: "0%",
            w: "100%",
            h: "10%",
          });
          slide.addImage({
            path: footImg,
            x: "0%",
            y: "90%",
            w: "100%",
            h: "10%",
          });

          slide.addText(variety, {
            x: "0%",
            y: "40%",
            w: "100%",
            h: 0.33,
            fontSize: 26,
            align: "center",

            color: "0b7751",
          });

          item[variety].manifolds.forEach((mani) => {
            const myDate = mani.created_at.slice(0, 10);
            if (mani.general_picture_large) {
              slide = pptx.addSlide();
              slide.addImage({
                path: mani.general_picture_large,
                x: "5%",
                y: "10%",
                w: "90%",
                h: "80%",
              });
              slide.addText(`${mani.grower_name}: General Picture`, {
                x: "5%",
                y: "90%",
                w: "90%",
                h: 0.33,
                fontSize: 18,
                align: "center",
                fill: "E1E1E1",
                color: "A1A1A1",
              });
              slide.addText(myDate, {
                x: "5%",
                y: "10%",
                w: "20%",
                h: 0.33,
                fontSize: 10,
                align: "left",
                color: "FFFF00",
              });
            }

            if (mani.female_sm && mani.male_1_sm) {
              slide = pptx.addSlide();
              slide.addImage({
                path: mani.female_sm,
                x: "5%",
                y: "10%",
                w: "40%",
                h: "80%",
              });
              slide.addImage({
                path: mani.male_1_sm,
                x: "50%",
                y: "10%",
                w: "40%",
                h: "80%",
              });

              slide.addText("Female Line", {
                x: "5%",
                y: "90%",
                w: "40%",
                h: 0.33,
                fontSize: 18,
                align: "center",
                fill: "E1E1E1",
                color: "A1A1A1",
              });
              slide.addText("Male Line 1", {
                x: "50%",
                y: "90%",
                w: "40%",
                h: 0.33,
                fontSize: 18,
                align: "center",
                fill: "E1E1E1",
                color: "A1A1A1",
              });
              slide.addText(myDate, {
                x: "5%",
                y: "10%",
                w: "20%",
                h: 0.33,
                fontSize: 10,
                align: "left",
                color: "FFFF00",
              });
              slide.addText(myDate, {
                x: "50%",
                y: "10%",
                w: "20%",
                h: 0.33,
                fontSize: 10,
                align: "left",
                color: "FFFF00",
              });
            }

            if (mani.female_sm && !mani.male_1_sm) {
              slide = pptx.addSlide();
              slide.addImage({
                path: mani.female,
                x: "30%",
                y: "10%",
                w: "40%",
                h: "80%",
              });

              slide.addText("Female Line", {
                x: "30%",
                y: "90%",
                w: "40%",
                h: 0.33,
                fontSize: 18,
                align: "center",
                fill: "E1E1E1",
                color: "A1A1A1",
              });
              slide.addText(myDate, {
                x: "30%",
                y: "10%",
                w: "20%",
                h: 0.33,
                fontSize: 10,
                align: "left",
                color: "FFFF00",
              });
            }
            if (mani.male_2_sm && mani.male_3_sm) {
              slide = pptx.addSlide();
              slide.addImage({
                path: mani.male_2_sm,
                x: "5%",
                y: "10%",
                w: "40%",
                h: "80%",
              });
              slide.addImage({
                path: mani.male_3_sm,
                x: "50%",
                y: "10%",
                w: "40%",
                h: "80%",
              });

              slide.addText("Male Line 2", {
                x: "5%",
                y: "90%",
                w: "40%",
                h: 0.33,
                fontSize: 18,
                align: "center",
                fill: "E1E1E1",
                color: "A1A1A1",
              });
              slide.addText("Male Line 3", {
                x: "50%",
                y: "90%",
                w: "40%",
                h: 0.33,
                fontSize: 18,
                align: "center",
                fill: "E1E1E1",
                color: "A1A1A1",
              });
              slide.addText(myDate, {
                x: "5%",
                y: "10%",
                w: "20%",
                h: 0.33,
                fontSize: 10,
                align: "left",
                color: "FFFF00",
              });
              slide.addText(myDate, {
                x: "50%",
                y: "10%",
                w: "20%",
                h: 0.33,
                fontSize: 10,
                align: "left",
                color: "FFFF00",
              });
            }

            if (mani.male_2_sm && !mani.male_3_sm) {
              slide = pptx.addSlide();
              slide.addImage({
                path: mani.male_2_sm,
                x: "30%",
                y: "10%",
                w: "40%",
                h: "80%",
              });

              slide.addText("Male Line 2", {
                x: "30%",
                y: "90%",
                w: "40%",
                h: 0.33,
                fontSize: 18,
                align: "center",
                fill: "E1E1E1",
                color: "A1A1A1",
              });
              slide.addText(myDate, {
                x: "30%",
                y: "10%",
                w: "20%",
                h: 0.33,
                fontSize: 10,
                align: "left",
                color: "FFFF00",
              });
            }
            if (mani.extra) {
              slide = pptx.addSlide();
              slide.addImage({
                path: mani.extra,
                x: "5%",
                y: "10%",
                w: "40%",
                h: "80%",
              });

              slide.addText("Extra Picture", {
                x: "5%",
                y: "90%",
                w: "40%",
                h: 0.33,
                fontSize: 18,
                align: "center",
                fill: "E1E1E1",
                color: "A1A1A1",
              });
              slide.addText(myDate, {
                x: "5%",
                y: "10%",
                w: "20%",
                h: 0.33,
                fontSize: 10,
                align: "left",
                color: "FFFF00",
              });
              if (mani.comment_extra && mani.comment_extra.length > 0) {
                slide.addText(mani.comment_extra, {
                  x: "50%",
                  y: "10%",
                  w: "40%",
                  h: "80%",
                  fontSize: 18,
                  align: "left",
                  fill: "FFFFFF",
                  color: "A1A1A1",
                });
              }
            }
          });
        }
      });
    });

    pptx.writeFile({ fileName: "Report_ppt" }).then((_) => {
      setPdfLoading(false);
    });
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className={classes.Screen}>
      {!isLoading && orders.length > 0 ? (
        <SquareCard>
          <div className={classes.CardContent}>
            <h2 className={classes.ContainerTitle}>
              Filter the orders
              {season?.name ? ` from season ${season.name}` : ""}
            </h2>
            <div className={classes.dropDownContainer}>
              <Dropdown
                data={clients}
                selected={selectClientHandler}
                value={clientSelected}
                myId="client_id"
                identifier="client"
              />

              {/* <Dropdown data={species} selected={selectEspeciesHandler} value={especiesSelected} myId="specie_id" identifier="specie" /> */}
            </div>
            <div className={classes.dropDownContainer}>
              <Dropdown
                data={cols}
                selected={selectCols}
                value={colOne}
                myId="value"
                identifier="col_1"
              />
              <Dropdown
                data={cols}
                selected={selectCols}
                value={colTwo}
                myId="value"
                identifier="col_2"
              />
            </div>

            <div className={classes.pdfContainer}>
              <ReactHTMLTableToExcel
                id="all-report"
                className={classes.Button}
                table="table-to-xls-full"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Download as XLS"
              />
              {!isLoading &&
                selected &&
                colOne.value !== -1 &&
                colTwo.value !== -1 && (
                  <>
                    <PDFDownloadLink
                      document={
                        <PDFPrevieweManifold
                          report={selected}
                          orders={ordersFiltered}
                          extraPictures={extraChecked}
                          colOne={colOne.value}
                          colTwo={colTwo.value}
                        />
                      }
                      fileName="report.pdf"
                      style={{
                        marginLeft: 10,
                        textDecoration: "none",
                        padding: "20px",
                        color: "#fff",
                        backgroundColor: "#4A00E0",
                        fontSize: 14,
                        borderRadius: 5,
                      }}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? "Loading document..." : "Download Pdf Images"
                      }
                    </PDFDownloadLink>
                    {/* <label className={classes.checkBox}>

                                    <input type="checkbox" name="name" checked={extraChecked} onChange={(e) => {

                                        setExtraChecked(e.target.checked)
                                    }} />
                                    Extra pictures?
                                </label> */}
                  </>
                )}
              {!isLoading &&
                selected &&
                colOne.value !== -1 &&
                colTwo.value !== -1 && (
                  <Button btnType="Save" clicked={pptGenerator}>
                    Download PPT
                  </Button>
                )}
            </div>
            <TableBig data={ordersFiltered} myId="table-to-xls-full" />
            {!isLoading &&
              selected &&
              colOne.value !== -1 &&
              colTwo.value !== -1 && (
                <>
                  <PDFViewer width="100%" height="700px">
                    <PDFPrevieweManifold
                      report={selected}
                      orders={ordersFiltered}
                      extraPictures={extraChecked}
                      colOne={colOne.value}
                      colTwo={colTwo.value}
                    />
                  </PDFViewer>
                </>
              )}
          </div>
        </SquareCard>
      ) : null}
      <Modal show={pdfLoading} modalClosed={() => {}}>
        <div>
          <h3 className={classes.loaderTitle}>Loading PPT</h3>
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReportScreen;
