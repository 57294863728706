import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import classes from './LoginScreen.module.css';
import Button from '../../components/UI/Button/Button';
import user from './resources/user.png';
import * as authActions from '../../store/auth/auth-actions';
import Loader from '../../components/UI/loader/Loader';

const UPDATE_FORM = 'UPDATE_FORM';

const formReducer = (state, action) => {
    if (action.type === UPDATE_FORM) {

        const updatedValues = {
            ...state.inputValues,
            [action.input]: action.value
        };
        const updatedValidities = {
            ...state.inputValidities,
            [action.input]: action.isValid
        };
        let updatedFormIsValid = true;
        for (const key in updatedValidities) {
            updatedFormIsValid = updatedFormIsValid && updatedValidities[key];
        }
        return {
            formIsValid: updatedFormIsValid,
            inputValidities: updatedValidities,
            inputValues: updatedValues
        };

        
    }
    return state;
};
const LoginScreen = props => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const mountedRef = useRef(true)

    const [formState, dispatchForm] = useReducer(formReducer,
        {
            inputValues: {
                mail: '',
                password: ''
            },
            inputValidities: {
                mail: false,
                password: false
            },
            formIsValid: false
        }
    );
    const inputChangeHandler = useCallback((event, identifier) => {
       
        let valid = true;
        if(event.target.value.trim().length === 0){
            valid = false;
        }
        dispatchForm({ type: UPDATE_FORM, input: identifier, value: event.target.value, isValid: valid })
    }, [dispatchForm])

    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, [])
    const login = async () => {
        setIsLoading(true);
        try {
            await dispatch(authActions.logIn(formState.inputValues.mail, formState.inputValues.password))
            if (!mountedRef.current) return null
            setIsLoading(false);
        } catch (error) {
            if (!mountedRef.current) return null
            console.log(error);
            setIsLoading(false);
        }
    }

    let content = (isLoading ? <Loader /> :
        <div className={classes.fullLogincontainer}>
            <div className={classes.loginContainer}>
                <div className={classes.Logo}>
                    <img className={classes.MyImg} src={user} alt="user"/>
                </div>
                <div className={classes.loginTitleContainer}>
                    <h1 className={classes.title}>Login to get access</h1>
                </div>

                <div className={classes.formContainer}>
                    <form className={classes.form}>
                        <input placeholder='username' className={classes.inputElement} onChange={(event) => inputChangeHandler(event, 'mail')} value={formState.inputValues.mail}/>
                        <input placeholder='password' className={classes.inputElement} onChange={(event) => inputChangeHandler(event, 'password')}type='password' value={formState.inputValues.password}/>
                        <div className={classes.buttonContainer}>
                            <Button btnType='Succes' clicked={login}> Log In</Button>

                        </div>
                    </form>
                </div>


            </div>
        </div>
    );


    return (content);
}

export default LoginScreen;