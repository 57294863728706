import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import classes from './CreateClientOrderScreen.module.css';
import { useSelector } from 'react-redux';
import Dropdown from '../../components/UI/Dropdown/Dropdown';
import SquareCard from '../../components/UI/Card/SquareCard';
import Loader from '../../components/UI/loader/Loader';
import Button from '../../components/UI/Button/Button';
import { getClients, getRatio, getSpecies, getVariety, addRequestsWeb,updateRequestsWeb, getAllFieldmans, getSeasons } from '../../helpers/RequestServices';
import { formatDate } from '../../helpers/utils';
const UPDATE_FORM = 'UPDATE_FORM';
const PRE_FORM = 'PRE_FORM';
const formReducer = (state, action) => {

    if (action.type === UPDATE_FORM) {
      
        const updatedValues = {
            ...state.inputsValues,
            [action.input]: action.value
        };

        const updatedValidities = {
            ...state.inputsValids,
            [action.input]: action.valid
        };
        

        let completeFormValid = true;
        for (const key in updatedValidities) {
            completeFormValid = completeFormValid && updatedValidities[key];
        }

        return {
            inputsValues: updatedValues,
            inputsValids: updatedValidities,
            formIsValid: completeFormValid
        }
    }
    if(action.type === PRE_FORM){
        return {
            inputsValues: {
            base: action.cr.base,
            required: action.cr.required_surface,

            
            
            client: { value: action.cr.client_id, name: action.cr.client },
            specie: { value: action.cr.specie_id, name: action.cr.specie },
            ratio: { value: action.cr.ratio_id, name: action.cr.ratio },
            variety: { value: action.cr.variety_id, name: action.cr.variety },
            fieldman: { value: action.cr.fieldman_id, name: action.cr.fieldman_name },
            season: { value: action.cr.season, name: '2022-2023' },
        },
        inputsValids: {
            base: true,
            required: true,
            
            
            client: true,
            specie: true,
            ratio: true,
            variety: true,
            fieldman: true,
            season: true
        },
        formIsValid: true}
    }
    return state;
}



const CreateClientOrderScreen = props => {
    const [isLoading, setIsLoading] = useState(true);
    const [clients, setClients] = useState([]);
    const [species, setSpecies] = useState([]);
    const [ratios, setRatios] = useState([]);
    const [varieties, setVarieties] = useState([]);
    const [fieldmans, setFieldmans] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const programId = useSelector(state => state.authStore.programId);
    const history = useHistory();
    const mode = props.location.mode;
    const cr = props.location.cr;

    useEffect(() => {
        setIsLoading(true);
        Promise.all([getClients(programId), getSpecies(programId), getVariety(programId), getRatio(programId), getAllFieldmans(programId), getSeasons()]).then(
            (res) => {

                setClients(res[0]);
                setSpecies(res[1]);
                setVarieties(res[2]);
                setRatios(res[3]);
                setFieldmans(res[4]);
                setSeasons(res[5]);
                if(mode === 'edit'){
                    dispatchForm({ type: PRE_FORM,cr:cr });

                }
                setIsLoading(false);
            }
        )


    }, []);
    const [formState, dispatchForm] = useReducer(formReducer,
        {
            inputsValues: {
                base: '',
                required: '',

                
                
                client: { value: -1, name: 'Select a client' },
                specie: { value: -1, name: 'Select an specie' },
                ratio: { value: -1, name: 'Select a ratio' },
                variety: { value: -1, name: 'Select a variety' },
                fieldman: { value: -1, name: 'Select a fieldman' },
                season: { value: -1, name: 'Select a season' },
            },
            inputsValids: {
                base: false,
                required: false,
                
                
                client: false,
                specie: false,
                ratio: false,
                variety: false,
                fieldman: false,
                season: false
            },
            formIsValid: false

        });

    const inputChangeHandler = useCallback((event, identifier) => {
  
        let valid = true;
        if (event.target.value.trim().length === 0) {
            valid = false;
        }
        dispatchForm({ type: UPDATE_FORM, input: identifier, value: event.target.value, valid: valid });
        
    }, [dispatchForm])

    const dropdownHandler = useCallback((value, name, identifier) => {
     
        dispatchForm({ type: UPDATE_FORM, input: identifier, value: { value: value, name: name }, valid: true });
       
    }, [dispatchForm])


    const createHandler = () => {
        if (formState.formIsValid) {
            if (mode === 'edit'){
                updateRequestsWeb(formState.inputsValues.specie.value, formState.inputsValues.variety.value, formState.inputsValues.base, formState.inputsValues.required, formState.inputsValues.base * formState.inputsValues.required, formState.inputsValues.ratio.value, formState.inputsValues.client.value, formState.inputsValues.season.value, formState.inputsValues.fieldman.value, cr.request_id)
                    .then(res => {
                        
                        history.goBack();
                    }).catch(err => {
                        console.log(err)
                    })
            }else{
                let currentDate = formatDate(new Date());
                addRequestsWeb(formState.inputsValues.specie.value, formState.inputsValues.variety.value, formState.inputsValues.base, formState.inputsValues.required, formState.inputsValues.base * formState.inputsValues.required, formState.inputsValues.ratio.value, formState.inputsValues.client.value, currentDate, formState.inputsValues.season.value, formState.inputsValues.fieldman.value, programId)
                    .then(res => {
                        
                        history.goBack();
                    }).catch(err => {
                        console.log(err)
                    })
            }
            
        }

    }
    return (isLoading ? <Loader /> :
        <div className={classes.Screen}>
            <div className={classes.Holder}>


                <SquareCard Bordered="Blue">
                    <div className={classes.container}>
                        <h1 className={classes.mainTitle}>Create Client Order</h1>

                        <div className={classes.formContainer}>
                            <form className={classes.form}>
                                
 
                                <Dropdown data={clients} selected={dropdownHandler} value={formState.inputsValues.client} myId="client_id" identifier="client" />

                                <Dropdown data={seasons} selected={dropdownHandler} value={formState.inputsValues.season} myId="season_id" identifier="season" />
              
                                
                                <Dropdown data={species} selected={dropdownHandler} value={formState.inputsValues.specie} myId="specie_id" identifier="specie" />
                                
                                <Dropdown data={varieties} selected={dropdownHandler} value={formState.inputsValues.variety} myId="variety_id" identifier="variety" />
                                
                                <Dropdown data={ratios} selected={dropdownHandler} value={formState.inputsValues.ratio} myId="ratio_id" identifier="ratio" />
                                
                                <Dropdown data={fieldmans} selected={dropdownHandler} value={formState.inputsValues.fieldman} myId="fieldman_id" identifier="fieldman" />
                                
                                <input placeholder='Base' className={classes.inputElement} onChange={(event) => inputChangeHandler(event, 'base')} value={formState.inputsValues.base} />
                                
                                <input placeholder='Required surface' className={classes.inputElement} onChange={(event) => inputChangeHandler(event, 'required')} value={formState.inputsValues.required} />
                                
                                <p className={classes.inputElement} >{(formState.inputsValues.base && formState.inputsValues.required)? `Target ${formState.inputsValues.base * formState.inputsValues.required} kg`: 'Target 0 kg'}</p>
                                
    

                            </form>
                            <div className={classes.buttonContainer}>
                                <Button btnType='Save' clicked={createHandler} isDisabled={!formState.formIsValid}>{mode === 'edit' ? 'EDIT': 'CREATE'}</Button>

                            </div>
                        </div>
                    </div>
                </SquareCard>
            </div>
        </div>)
}

export default CreateClientOrderScreen;