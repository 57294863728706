import axios from '../axios';
import Report from '../models/Report';
import Field from '../models/Field';

export const GET_FIELDS = 'GET_FIELDS';
export const GET_REPORTS = 'GET_REPORTS';
export const ADD_CLIENT = 'ADD_CLIENT';
export const PUT_CLIENT = 'PUT_CLIENT';
export const FILTER_CLIENT = 'FILTER_CLIENT';
export const GET_CLIENTS = 'GET_CLIENTS';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const GET_SPECIES = 'GET_SPECIES';
export const ADD_SPECIE = 'ADD_SPECIE';
export const DELETE_SPECIE = 'DELETE_SPECIE';
export const PUT_SPECIE = 'PUT_SPECIE';
export const GET_RATIOS = 'GET_RATIOS';
export const ADD_RATIO = 'ADD_RATIO';
export const DELETE_RATIO = 'DELETE_RATIO';
export const PUT_RATIO = 'PUT_RATIO';
export const GET_VARIETIES = 'GET_VARIETIES';
export const ADD_VARIETY = 'ADD_VARIETY';
export const DELETE_VARIETY = 'DELETE_VARIETY';
export const PUT_VARIETY = 'PUT_VARIETY';

export const getFields = () => {
    return async dispatch => {
        try {
            const response = await axios.get('/fields');
            const resData = await response.data;
            const loadFields = [];
            for (const item in resData) {
                loadFields.push(new Field(
                    resData[item].field_id,
                    resData[item].name,
                    resData[item].location_name,
                    resData[item].client_id,
                    resData[item].job_number,
                    resData[item].grower_id,
                    resData[item].specie_id,
                    resData[item].variety_id,
                    resData[item].ratio_id,
                    resData[item].code,
                    resData[item].stimated_sup,
                    resData[item].real_sup,
                    resData[item].base_client,
                    resData[item].base_grower,
                    resData[item].fieldman_id,
                    resData[item].lat,
                    resData[item].lng
                ));
            }
            dispatch({ type: GET_FIELDS, loadFields: loadFields })
        } catch (error) {
            throw error;
        }
    }
}


export const getReports = () => {
    return async dispatch => {
        try {
            const response = await axios.get('/fieldsreport');
            const resData = await response.data;
            const loadedReports = [];
            for (const item in resData) {
                loadedReports.push(new Report(
                    resData[item].report_id,
                    resData[item].field_id,
                    resData[item].report_type,
                    resData[item].active,
                    resData[item].female,
                    resData[item].male_1,
                    resData[item].male_2,
                    resData[item].male_3,
                    resData[item].image_large_1,
                    resData[item].image_large_2,
                    resData[item].image_large_3,
                    resData[item].image_small_1,
                    resData[item].image_small_2,
                    resData[item].image_small_3

                ))
            }
            dispatch({ type: GET_REPORTS, reports: loadedReports })
        } catch (err) {
            throw err;
        }

    }
};

export const getClients = () => {

    return async (dispatch, getState) => {
        try {
            const response = await axios.get(`/clients?program_id=${getState().authStore.programId}`);
            const resData = await response.data;
           
            dispatch({ type: GET_CLIENTS, clients: resData });
        } catch (error) {
            throw error;
        }
    }
}
export const addClient = (name) => {

    return async (dispatch, getState) => {
        try {
            const response = await axios.post('/clients', {
                name: name,
                program_id: getState().authStore.programId
            });
            const resData = await response.data;
            
            dispatch({ type: ADD_CLIENT, id: resData, name: name });
        } catch (error) {
            throw error;
        }
    }
};
export const updateClient = (id, name) => {

    return async (dispatch, getState) => {
        let clients = getState().mainStore.clients;
        let client = clients.find(item=>item.client_id === id);
        try {
            const response = await axios.put(`/clients/${id}`, {
                name: name
            });
            
           
            if(response.status !== 200){
             
                throw new Error("Can't delete");
            }
            client.name = name;
            dispatch({ type: PUT_CLIENT, clients: clients });
        } catch (error) {
            throw error;
        }
    }
};

export const deleteClient = (id) => {

    return async (dispatch, getState) => {
        let clients = getState().mainStore.clients;
        clients = clients.filter(item=>item.client_id !== id);
        try {
            const response = await axios.delete(`/clients/${id}`);
            
            
         
            if(response.status !== 200){
             
                throw new Error("Can't delete");
            }
            dispatch({ type: DELETE_CLIENT, clients: clients });
        } catch (error) {
            throw error;
        }
    }
};


export const filterPosts = (value) => {
    return { type: FILTER_CLIENT, value: value };
}


export const getSpecies = () => {

    return async (dispatch, getState) => {
        try {
            const response = await axios.get(`/species?program_id=${getState().authStore.programId}`);
            const resData = await response.data;
          
            dispatch({ type: GET_SPECIES, species: resData });
        } catch (error) {
            throw error;
        }
    }
}
export const addSpecie = (name) => {

    return async (dispatch, getState) => {
        try {
            const response = await axios.post('/species', {
                name: name,
                program_id: getState().authStore.programId
            });
            const resData = await response.data;
      
            if(response.status !== 201){
            
                throw new Error("Can't update");
            }
    
            dispatch({ type: ADD_SPECIE, id: resData, name: name });
        } catch (error) {
            throw error;
        }
    }
}
export const updateSpecie = (id, name) => {

    return async (dispatch, getState) => {
        let species = getState().mainStore.species;
        let specie = species.find(item=>item.specie_id === id);
        try {
            const response = await axios.put(`/species/${id}`, {
                name: name
            });
            
            if(response.status !== 200){
      
                throw new Error("Can't update");
            }
            specie.name = name;
            dispatch({ type: PUT_SPECIE, species: species });
        } catch (error) {
            throw error;
        }
    }
};

export const deleteScpecie = (id) => {

    return async (dispatch, getState) => {
        let species = getState().mainStore.species;
    
        species = species.filter(item=>item.specie_id !== id);
  

        try {
            const response = await axios.delete(`/species/${id}`);
        

            if(response.status !== 200){
       
                throw new Error("Can't delete");
            }
            dispatch({ type: DELETE_SPECIE, species: species });
        } catch (error) {
            throw error;
        }
    }
};

export const getRatios = () => {

    return async (dispatch, getState) => {
        try {
            const response = await axios.get(`/ratio?program_id=${getState().authStore.programId}`);
            const resData = await response.data;
    
            dispatch({ type: GET_RATIOS, ratios: resData });
        } catch (error) {
            throw error;
        }
    }
}
export const addRatio = (name) => {

    return async (dispatch, getState) => {
        try {
            const response = await axios.post('/ratio', {
                name: name,
                program_id: getState().authStore.programId
            });
            const resData = await response.data;

            dispatch({ type: ADD_RATIO, id: resData, name: name });
        } catch (error) {

        }
    }
}

export const updateRatio = (id, name) => {

    return async (dispatch, getState) => {
        let ratios = getState().mainStore.ratios;
        let ratio = ratios.find(item=>item.ratio_id === id);
        try {
            const response = await axios.put(`/ratio/${id}`, {
                name: name
            });
            
   
            if(response.status !== 200){
     
                throw new Error("Can't update");
            }
            ratio.name = name;
            dispatch({ type: PUT_RATIO, ratios: ratios });
        } catch (error) {
            throw error;
        }
    }
};

export const deleteRatio = (id) => {

    return async (dispatch, getState) => {
        let ratios = getState().mainStore.ratios;
        ratios = ratios.filter(item=>item.ratio_id !== id);
        try {
            const response = await axios.delete(`/ratio/${id}`);
            
            

            if(response.status !== 200){
          
                throw new Error("Can't delete");
            }
            dispatch({ type: DELETE_RATIO, ratios: ratios });
        } catch (error) {
            throw error;
        }
    }
};


export const getVariety = () => {

    return async (dispatch, getState) => {
        try {
            const response = await axios.get(`/variety?program_id=${getState().authStore.programId}`);
            const resData = await response.data;
 
            dispatch({ type: GET_VARIETIES, varieties: resData });
        } catch (error) {
            throw error;
        }
    }
}
export const addVariety = (name) => {

    return async (dispatch, getState) => {
        try {
            const response = await axios.post('/variety', {
                name: name,
                program_id: getState().authStore.programId
            });
            const resData = await response.data;
       
            dispatch({ type: ADD_VARIETY, id: resData, name: name });
        } catch (error) {

        }
    }
}

export const updateVariety = (id, name) => {

    return async (dispatch, getState) => {
        let varieties = getState().mainStore.varieties;
        let variety = varieties.find(item=>item.variety_id === id);
        try {
            const response = await axios.put(`/variety/${id}`, {
                name: name
            });
            
    
            if(response.status !== 200){
        
                throw new Error("Can't update");
            }
            variety.name = name;
            dispatch({ type: PUT_VARIETY, varieties: varieties });
        } catch (error) {
            throw error;
        }
    }
};

export const deleteVariety = (id) => {

    return async (dispatch, getState) => {
        let varieties = getState().mainStore.varieties;
        varieties = varieties.filter(item=>item.variety_id !== id);
        try {
            const response = await axios.delete(`/variety/${id}`);
          
            
  
            if(response.status !== 200){
   
                throw new Error("Can't delete");
            }
            dispatch({ type: DELETE_VARIETY, varieties: varieties });
        } catch (error) {
            throw error;
        }
    }
};