
   
import React, { useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import { Font } from '@react-pdf/renderer'

import hero from './resources/head_piga.PNG';
import foot from './resources/foot_piga.PNG'
import latoRegular from './fonts/Lato-Regular.ttf';
import latoBold from './fonts/Lato-Bold.ttf';
import latoThin from './fonts/Lato-Thin.ttf';

const Aux = props => props.children;
// Create Document Component

Font.register({
    family: 'Lato',
    fonts: [
        {
            src: latoRegular
        },
        {
            src: latoBold,
            fontWeight: 'bold'
        },
        {
            src: latoThin,
            fontWeight: 'thin'
        }
    ]
})

const MyDocument = (props) => {
    const { report, orders, extraPictures, colOne, colTwo } = props;
    const myKeys =
    {surface: {
        cols: ["surface_e",
            "surface_r",
            ],

        head: ["Estimated Surface",
            "Real Surface",
            ]
    },
    plants: {
        cols: ["plants_real",
            "plants_r_m1",
            "plants_r_m2"
            
            ],

        head: ["Plants/M Female",
        "Plants/M Male 1",
        "Plants/M Male 2",
            ]
    },
        sowing: {
            cols: ["sowing_female",
                "sowing_male_1",
                "sowing_male_2",
                "sowing_male_3",],

            head: ["sowing female",
                "sowing male 1",
                "sowing male 2",
                "sowing male 3"]
        },
        transplanting: {
            cols: ["transplanting_female",
                "transplanting_male_1",
                "transplanting_male_2",
                "transplanting_male_3",],
            head: ["transplanting female",
                "transplanting male 1",
                "transplanting male 2",
                "transplanting male 3"]
        },
        transplanting_f: {
            cols: ["transplanting_f_female",
                "transplanting_f_male_1",
                "transplanting_f_male_2",
                "transplanting_f_male_3",],
            head: ["transplanting finish female",
                "transplanting finish male_1",
                "transplanting finish male_2",
                "transplanting finish male_3"]
        },
        commercial: {
            cols: ["commercial_female",
                "commercial_male_1",
                "commercial_male_2",
                "commercial_male_3",],
            head: ["commercial female",
                "commercial male 1",
                "commercial male 2",
                "commercial male 3"]
        },
        cut: {
            cols: ["cut_female",
                "cut_male_1",
                "cut_male_2",
                "cut_male_3",],
            head: ["cut female",
                "cut male 1",
                "cut male 2",
                "cut male 3"]
        },
        bolting: {
            cols: ["bolting_female",
                "bolting_male_1",
                "bolting_male_2",
                "bolting_male_3",],
            head: ["bolting female",
                "bolting male 1",
                "bolting male 2",
                "bolting male 3"]
        },
        flowering_1: {
            cols: ["flowering_1_female",
                "flowering_1_male_1",
                "flowering_1_male_2",
                "flowering_1_male_3",],
            head: ["flowering 1 female",
                "flowering 1 male 1",
                "flowering 1 male 2",
                "flowering 1 male 3"]
        },
        flowering_5: {
            cols: ["flowering_5_female",
                "flowering_5_male_1",
                "flowering_5_male_2",
                "flowering_5_male_3",],
            head: ["flowering 5 female",
                "flowering 5 male 1",
                "flowering 5 male 2",
                "flowering 5 male 3"]
        },
        flowering_0: {
            cols: ["flowering_0_female",
                "flowering_0_male_1",
                "flowering_0_male_2",
                "flowering_0_male_3",],
            head: ["flowering 0 female",
                "flowering 0 male 1",
                "flowering 0 male 2",
                "flowering 0 male 3"]
        },

        nicking: {
            cols: ["nicking_female",
                "nicking_male_1",
                "nicking_male_2",
                "nicking_male_3",],
            head: ["nicking female",
                "nicking male 1",
                "nicking male 2",
                "nicking male 3"]
        },
        staking: { cols: ["staking"], head: ["staking"] },
        male_elimination: { cols: ["male_elimination"], head: ['male elimination'] },
        last_irrigation: { cols: ["last_irrigation"], head: ['last irrigation'] },
        harvest: { cols: ["harvest"], head: ['harvest'] },
        dessicant: { cols: ["desiccant"], head: ['desiccant'] },
        bee: { cols: ["bee"], head: ['bee'] }
    }


    return (<Document>
        <Page size="A4" style={styles.pageHome} orientation='landscape'>
            <View style={styles.header}>
                <Image style={styles.imageBig} source={hero} />
            </View>
            <View >




                <View style={styles.card}>
                    <View style={styles.mainTitleContainer}>

                        <Text style={styles.cardTile}>{report[0].data.client} report</Text>
                    </View>

                    <View style={styles.summaryContent}>
                        {report.map((item, index) => (
                            <Aux key={index}>
                                <Text style={styles.textItem}> Specie: {item.data.specie}</Text>
                            </Aux>
                        ))}


                    </View>
                </View>
                <View style={styles.footer}>
                    <Image style={styles.imageBig} source={foot} />
                </View>
            </View>


        </Page>


        {/* {(orders && orders.length > 0 && colOne && colTwo) && <Page orientation='landscape' style={styles.pageHome}>
            <View style={styles.header}>
                <Image style={styles.image} source={hero} />
            </View>
            <View style={styles.cardTable}>

                <Aux>

                    <View style={styles.headTable}>
                        <Text style={styles.headText}>Variety</Text>
                        <Text style={styles.headText}>Ratio</Text>
                        <Text style={styles.headText}>Base</Text>
                        <Text style={styles.headText}>Grower</Text>
                        {myKeys[colOne].head.map(itemCol => (<Text style={styles.headText}>{itemCol}</Text>
                        ))}
                        {myKeys[colTwo].head.map(itemCol => (<Text style={styles.headText}>{itemCol}</Text>
                        ))}
                    </View>
                    {orders.map(item => {
                        return item.growers.map(grower => (
                            <View key={grower.grower_id} >


                                {(grower.fields && grower.fields.length > 0) && grower.fields.map(field => (<View key={field.field_id} style={styles.resumeTable}>
                                    <Text style={styles.resumeText}>{item.variety}</Text>
                                    <Text style={styles.resumeText}> {item.ratio}</Text>
                                    <Text style={styles.resumeText}>{item.base}</Text>
                                    <Text style={styles.resumeText}>{grower.name}</Text>
                                    {myKeys[colOne].cols.map(itemCol => (<Text style={styles.resumeText}>{field.reports[0][itemCol] ? typeof field.reports[0][itemCol] === 'string' ? field.reports[0][itemCol].replace("T00:00:00.000Z", "") : field.reports[0][itemCol] : ' - '}</Text>
                                    ))}
                                    {myKeys[colTwo].cols.map(itemCol => (<Text style={styles.resumeText}>{field.reports[0][itemCol] ? typeof field.reports[0][itemCol] === 'string' ? field.reports[0][itemCol].replace("T00:00:00.000Z", "") : field.reports[0][itemCol] : ' - '}</Text>
                                    ))}

                                </View>))}



                            </View>

                        ))
                    })}
                </Aux>
            </View>
            <View style={styles.footer}>
                <Image style={styles.imageBig} source={foot} />
            </View>
        </Page>} */}

        {
            report.map((item, index) => (
                <Aux key={index}>
                    {item.varieties.map((variety, index) => (
                        <Aux key={index}>
                            {item[variety].manifolds.length > 0 && <Page orientation='landscape' style={styles.pageHome}>
                                <View style={styles.header}>
                                    <Image style={styles.imageBig} source={hero} />
                                </View>
                                <View style={styles.cardBig}>
                                    <View style={styles.mainTitleContainer}>

                                        <Text style={styles.cardTile}> Variety {variety}</Text>
                                    </View>
                                </View>
                                <View style={styles.footer}>
                                    <Image style={styles.imageBig} source={foot} />
                                </View>
                            </Page>}
                            {item[variety].manifolds.map((mani, index) => (
                                <Aux key={index}>
                                    {mani.general_picture_small && <Page orientation='landscape'>
                                        <View style={styles.generalContainer}>
                                            {/* <View style={styles.imageContainerBig}>
                                    
                                </View> */}
                                            <Image style={styles.image} source={mani.general_picture_large} />
                                            <View style={styles.generalTitleContainer}>
                                                <Text style={styles.textItem}>{mani.grower_name}: General Picture</Text>
                                            </View>

                                        </View>
                                    </Page>}
                                    {(mani.female_sm || mani.male_1_sm) && <Page orientation='landscape'>
                                        <View style={styles.halfContainer}>


                                            {mani.female_sm && <View style={styles.imageContainerSmall}>
                                                <Image style={styles.image} source={mani.female_sm}  />
                                                <View style={styles.generalTitleContainer}>
                                                    <Text style={styles.textItem}>Female Line</Text>
                                                </View>
                                            </View>}
                                            {mani.male_1_sm && <View style={styles.imageContainerSmall}>
                                                <Image style={styles.image} source={mani.male_1_sm} />
                                                <View style={styles.generalTitleContainer}>
                                                    <Text style={styles.textItem}>Male Line 1</Text>
                                                </View>
                                            </View>}
                                        </View>
                                    </Page>}
                                    {(mani.male_2_sm || mani.male_3_sm) && <Page orientation='landscape'>
                                        <View style={styles.halfContainer}>


                                            {mani.male_2_sm && <View style={styles.imageContainerSmall}>
                                                <Image style={styles.image} source={mani.male_2_sm}  />
                                                <View style={styles.generalTitleContainer}>
                                                    <Text style={styles.textItem}>Male Line 2</Text>
                                                </View>
                                            </View>}
                                            {/* {mani.male_3_sm && <View style={styles.imageContainerSmall}>
                                                <Image style={styles.image} source={mani.male_3_sm} />
                                                <View style={styles.generalTitleContainer}>
                                                    <Text style={styles.textItem}>Male Line 3</Text>
                                                </View>
                                            </View>} */}
                                        </View>
                                    </Page>}

                                </Aux>
                            ))}
                        </Aux>


                    ))}
                </Aux>
            ))
        }





    </Document>)
}


// Create styles
const styles = StyleSheet.create({
    pageHome: {

        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    page: {
        flexDirection: 'column',
        backgroundColor: '#f0eeee',
        padding: 10
    },
    header: {
        width: '100%',
        height: 80
    },
    footer: {
        width: '100%',
        height: 80
    },
    homeLeft: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%'
    },
    homeRight: {
        flex: 1
    },
    imageHeadContainer: {
        width: 150,
        height: 60
    },
    imageHeader: {
        width: '100%',
        height: '100%',
    },
    mainTitleContainer: {
        marginBottom: 5
    },
    title: {
        fontSize: 16,
        fontFamily: 'Lato'
    },
    card: {
        margin: 5,
        backgroundColor: 'white',
        borderRadius: 10,
        padding: 10,
        marginTop: 30
    },
    cardBig: {
        position: 'relative',
        flex: 1,

        backgroundColor: 'white',


        alignItems: 'center',
        justifyContent: 'center'
    },
    cardTitleContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingBottom: 10
    },
    cardTile: {
        color: '#0b7751',
        fontSize: 40,
        fontFamily: 'Lato',
        fontWeight: 'bold'
    },
    fieldTitle: {
        fontSize: 40
    },
    imageContainer: {
        width: 100,
        height: 100,
        marginRight: 6,


    },
    imageContainerBig: {
        width: '100%',
        height: '90%',
        padding: 10,
        backgroundColor: 'red'


    },
    imageContainerSmall: {
        width: '50%',
        height: '100%',
        padding: 10


    },
    image: {
        width: '100%',
        height: '90%',
    },
    imageBig: {
        width: '100%',
        height: '100%',
    },
    summaryContent: {
        marginTop: 5,
        marginLeft: 5
    },
    textItem: {
        fontSize: 24,
        marginVertical: 8,
        color: '#434343',
        fontFamily: 'Lato'
    },
    tableContainer: {
        marginTop: 10,
        flex: 1
    },
    table: {
        // borderRight: .8,
        // borderLeft : .8,
        // borderColor: '#ddd',
        // borderStyle: 'solid',
    },
    head: {
        flexDirection: 'row',
        backgroundColor: '#0b7751',


    },
    headOrange: {
        flexDirection: 'row',
        backgroundColor: '#F7971E',
    },
    headText: {
        color: 'white',
        fontSize: 14,
    },
    row: {
        flexDirection: 'row',
        backgroundColor: '#faf9f9'

    },
    rowDark: {
        flexDirection: 'row',
        backgroundColor: '#f0eeee'

    },
    rowColumn: {
        flex: 1,
        padding: 5,
        borderRight: .8,

        borderColor: '#ddd',
        borderStyle: 'solid',
        margin: 0
    },
    rowColumnNoBorder: {
        flex: 1,
        padding: 5,

        margin: 0
    },
    headColumn: {

        flex: 1,
        padding: 2
    },
    rowText: {
        fontSize: 12
    },
    banner: {
        position: 'absolute',
        bottom: 0,
        height: 70,
        width: '100%'

    },
    halfContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
    },
    generalContainer: {
        position: 'relative',
        alignItems: 'center',

        height: '100%',
        width: '100%',
        flex: 1,

    },
    generalTitleContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: '10%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardTable: {
        position: 'relative',
        flex: 1,
        padding: 10,
        justifyContent: 'center'
    },
    tableHero: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: 80
    },
    resumeTable: {
        flexDirection: 'row',
        borderColor: '#ccc',
        borderWidth: 1,
        padding: 4
    },
    headTable: {
        flexDirection: 'row',
        borderColor: '#ccc',
        borderWidth: 1,
        padding: 4,
        backgroundColor: '#005C97'
    },
    resumeColumn: {
        flex: 1,
        flexDirection: 'row'
    },
    resumeText: {
        fontSize: 10,
        marginVertical: 2,
        color: '#434343',
        fontFamily: 'Lato',
        width: 70
    },
    headText: {
        fontSize: 10,
        marginVertical: 2,
        color: '#fff',
        fontFamily: 'Lato',
        width: 70
    }
});
export default MyDocument;
