import React from 'react';

import classes from './TableBig.module.css';
import Aux from '../../../hoc/MyAux';

const TableReports = props => {
    const { data } = props;
  
    const reportsDates = [

        { id: 2, title: 'Sowing Date', state: ['sowing_female', 'sowing_male_1', 'sowing_male_2', 'sowing_male_3'] },
        { id: 4, title: 'Transplanting Init', state: ['transplanting_female', 'transplanting_male_1', 'transplanting_male_2', 'transplanting_male_3'] },
        { id: 5, title: 'Transplanting Finish', state: ['transplanting_f_female', 'transplanting_f_male_1', 'transplanting_f_male_2', 'transplanting_f_male_3'] },
        { id: 6, title: 'Commercial stage', state: ['commercial_female', 'commercial_male_1', 'commercial_male_2', 'commercial_male_3'] },
        { id: 7, title: 'Cut/pruning', state: ['cut_female', 'cut_male_1', 'cut_male_2', 'cut_male_3'] },
        { id: 8, title: 'Begining of bolting', state: ['bolting_female', 'bolting_male_1', 'bolting_male_2', 'bolting_male_3'] },
        { id: 9, title: 'Flowering 1', state: ['flowering_1_female', 'flowering_1_male_1', 'flowering_1_male_2', 'flowering_1_male_3'] },
        { id: 10, title: 'Flowering 50', state: ['flowering_5_female', 'flowering_5_male_1', 'flowering_5_male_2', 'flowering_5_male_3'] },
        { id: 11, title: 'Flowering -1', state: ['flowering_0_female', 'flowering_0_male_1', 'flowering_0_male_2', 'flowering_0_male_3'] },
        { id: 12, title: 'Nicking Adjust', state: ['nicking_female', 'nicking_male_3', 'nicking_male_2', 'nicking_male_1'] }]

    const columns = [
        "Client", "Specie", "Variety", "Ratio", "Base",
        "Grower", "Field",
        "Estimated surface",
        "Real Surface",
        "Plants required",
        "Plants real",
        "sowing female",
        "sowing male_1",
        "sowing male_2",
        "sowing male_3",
        "transplanting female",
        "transplanting male_1",
        "transplanting male_2",
        "transplanting male_3",
        "transplanting finish female",
        "transplanting finish male_1",
        "transplanting finish male_2",
        "transplanting finish male_3",
        "commercial female",
        "commercial male_1",
        "commercial male_2",
        "commercial male_3",
        "cut female",
        "cut male_1",
        "cut male_2",
        "cut male_3",
        "bolting female",
        "bolting male_1",
        "bolting male_2",
        "bolting male_3",
        "flowering 1% female",
        "flowering 1% male_1",
        "flowering 1% male_2",
        "flowering 1% male_3",
        "flowering 5% female",
        "flowering 5% male_1",
        "flowering 5% male_2",
        "flowering 5% male_3",
        "flowering -1% female",
        "flowering -1% male_1",
        "flowering -1% male_2",
        "flowering -1% male_3",

        "nicking female",
        "nicking male 1",
        "nicking male 2",
        "nicking male 3",
        
        

        "staking",
        "bee",
        "male elimination",
        "last irrigation",
        "harvest",
        "desiccant",
        "harvest system",
        "treshing system",
        "treshing machine"]

    const myKeys = [
        "surface_e",
        "surface_r",
        "plants_req",
        "plants_real",
        "sowing_female",
        "sowing_male_1",
        "sowing_male_2",
        "sowing_male_3",
        "transplanting_female",
        "transplanting_male_1",
        "transplanting_male_2",
        "transplanting_male_3",
        "transplanting_f_female",
        "transplanting_f_male_1",
        "transplanting_f_male_2",
        "transplanting_f_male_3",
        "commercial_female",
        "commercial_male_1",
        "commercial_male_2",
        "commercial_male_3",
        "cut_female",
        "cut_male_1",
        "cut_male_2",
        "cut_male_3",
        "bolting_female",
        "bolting_male_1",
        "bolting_male_2",
        "bolting_male_3",
        "flowering_1_female",
        "flowering_1_male_1",
        "flowering_1_male_2",
        "flowering_1_male_3",
        "flowering_5_female",
        "flowering_5_male_1",
        "flowering_5_male_2",
        "flowering_5_male_3",
        "flowering_0_female",
        "flowering_0_male_1",
        "flowering_0_male_2",
        "flowering_0_male_3",

        "nicking_female",
        "nicking_male_1",
        "nicking_male_2",
        "nicking_male_3",
        
        

        "staking",
        "bee",
        "male_elimination",
        "last_irrigation",
        "harvest",
        "desiccant",
        "harvest_system",
        "treshing_system",
        "treshing_machine"]
    return (
        data ? <div className={classes.tableContainer}>

            <table className={classes.table} id={props.myId}>

                <thead>

                    <tr>
                        {columns.map(col => <th key={col} className={classes.myCol}>{col}</th>)}

                        {/* <th>Male 2</th>
                        <th>Male 3</th> */}

                    </tr>
                </thead>


                <tbody>
                    {data.map(item => {
                        // let qnt_growers = item.growers.length;
                        return item.growers.map(grower => (

                            <Aux key={grower.grower_id}>
                                {grower.fields.length > 0 ? grower.fields.map((field, i) => (
                                    <Aux key={field.field_id}>

                                        <tr>
                                        
                                            <td>{item.client}</td>
                                            <td>{item.specie}</td>
                                            <td>{item.variety}</td>
                                            <td>{item.ratio}</td>
                                            <td>{item.base}</td>
                                            <td>{grower.name}</td>
                                            <td >{field.name}</td>
                                            {myKeys.map(col => <td key={col}>{field.reports[0][col] ?  typeof field.reports[0][col] === 'string'? field.reports[0][col].replace("T00:00:00.000Z", ""): field.reports[0][col] : ' - '}</td>)}
                                        </tr>
                                        {/* {i === 0 ?
                                            <tr>
                                            
                                                <td rowSpan={grower.fields.length}>{grower.name ? grower.name : ' - '}</td>
                                                <td >{field.name}</td>
                                                {myKeys.map(col => <td key={col}>{field.reports[0][col] ? field.reports[0][col] : ' - '}</td>)}</tr> :

                                            <tr>
                                                <td >{field.name}</td>
                                                {myKeys.map(col => <td key={col}>{field.reports[0][col] ? field.reports[0][col] : ' - '}</td>)}</tr>} */}
                                    </Aux>
                                )

                                ) : null}
                            </Aux>
                        ))
                    }
                    )}



                </tbody>



            </table>

        </div> : null
    );

}

export default TableReports;