
import axios from "../../axios"
export const createGrower = (name, location, request_id) => {
    return async (dispatch, getState) => {
      try {
    

        const response = await axios.post('growers', {
            "name": name,
            "location": location,
            "request_id": request_id

          })
        const resData = await response.json();
        if (!response.ok) {
  
          throw new Error('BAD')
        }
     
  
      } catch (error) {
          
        return error;
      }
    }
  
  
  }