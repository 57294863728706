import axios from "../axios";

export const getClients = async (programId) => {
  try {
    const response = await axios.get(`clients?program_id=${programId}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getSpecies = async (programId) => {
  try {
    const response = await axios.get(`species?program_id=${programId}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getVariety = async (programId) => {
  try {
    const response = await axios.get(`variety?program_id=${programId}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getRatio = async (programId) => {
  try {
    const response = await axios.get(`ratio?program_id=${programId}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getSeasons = async () => {
  try {
    const response = await axios.get("season");
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getClientRequest = async (season, programId) => {
  try {
    let query = `joinedrequest?program_id=${programId}`;
    if(season.value> -1){
      query = query + `&season=${season.value}`
    }
    const response = await axios.get(query);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getGrowersByRequest = async (id) => {
  try {
    const response = await axios.get(`growerrequests/${id}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getGrowers = async () => {
  try {
    const response = await axios.get("growers");
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getFieldMultipleGrowers = async (args) => {
  try {
    const response = await axios.get(`fieldsmultiplegrowers?${args}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getReportsdMultipleFields = async (args) => {
  try {
    const response = await axios.get(`reportsmultiple?${args}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getFieldGrowers = async () => {
  try {
    const response = await axios.get("fieldsgrowers");
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getReportsSurfaceMultipleFields = async (args) => {
  try {
    const response = await axios.get(`reportsurfacemultiple?${args}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getManifoldsMultipleFields = async (args) => {
  try {
    const response = await axios.get(`manifoldmultiple?${args}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getManifoldsWarnMultiple = async (args) => {
  try {
    const response = await axios.get(`manifoldwarningmultiple?${args}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getPicturesMultipleFields = async (args) => {
  try {
    const response = await axios.get(`reportpictures?${args}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const addRequestsWeb = async (
  specie,
  variety,
  base,
  required_surface,
  target_kg,
  ratio,
  client_id,
  created_at,
  season,
  fieldman,
  programId
) => {
  try {
    const response = await axios.post("requests", {
      specie: specie,
      variety: variety,
      base: +base,
      required_surface: +required_surface,
      target_kg: +target_kg,
      ratio: ratio,
      client_id: client_id,
      fieldman_id: fieldman,
      created_at: created_at,
      season: season,
      program_id: programId
    });
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const updateRequestsWeb = async (
  specie,
  variety,
  base,
  required_surface,
  target_kg,
  ratio,
  client_id,
  season,
  fieldman,
  request_id
) => {
  try {
    const response = await axios.put(`requests/${request_id}`, {
      specie: specie,
      variety: variety,
      base: +base,
      required_surface: +required_surface,
      target_kg: +target_kg,
      ratio: ratio,
      client_id: client_id,
      fieldman_id: fieldman,
      season: season,
    });
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getAllReports = async () => {
  try {
    const response = await axios.get("reports");
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getLastManifoldPictures = async () => {
  try {
    const response = await axios.get("manifold_pics");
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};


export const getAllFieldmans = async (programId) => {
  try {
    const response = await axios.get(`fieldman?program_id=${programId}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const deleteOrder = async (id) => {
  try {
    const response = await axios.delete(`requests/${id}`);
  } catch (error) {
    throw error;
  }
};

export const deleteGrower = async (id) => {
  try {
    const response = await axios.delete(`growers/${id}`);
  } catch (error) {
    throw error;
  }
};


export const getBusinessTotals = async (programId) => {
  try {
    const response = await axios.get(`business_totals?program_id=${programId}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getBusinessByFieldman = async (programId) => {
  try {
    const response = await axios.get(`business_totals_fieldman?program_id=${programId}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};

export const getBusinessTenDays = async (programId) => {
  try {
    const response = await axios.get(`business_totals_ten_days?program_id=${programId}`);
    const resData = await response.data;

    return resData;
  } catch (err) {
    throw err;
  }
};