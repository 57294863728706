import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Loader from "../../components/UI/loader/Loader";
import classes from "./ClientOrderDetailScreen.module.css";
import * as RequestServices from "../../helpers/RequestServices";
import SquareCard from "../../components/UI/Card/SquareCard";
import FarmerImg from "./resources/man.png";
import Farm from "./resources/farm.png";
import Summary from "./resources/summary.png";
import Table from "../../components/UI/TableReports/TableReports";
import TableBig from "../../components/UI/TableBig/TableBig";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Aux from "../../hoc/MyAux";
import Modal from "../../components/UI/Modal/Modal";
import Button from "../../components/UI/Button/Button";
import ImageCard from '../../components/UI/ImageCard/ImageCard';
import { createGrower } from "../../store/main/growers-actions"
import { Line } from 'react-chartjs-2';

const ClientOrderDetailScreen = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [modalDelete, setModalDelete] = useState(false);
  const [manifolds, setManifolds] = useState();
  const [fullData, setFullData] = useState();
  const [modalType, setModalType] = useState(0);
  const [selectedId, setSelectedId] = useState();
  const [dataGraph, setDataGraph] = useState({});
  const [manifoldVisible, setManifoldVisible] = useState();
  const [modalGrower, setModalGrower] = useState(false);
  const [groweName, setGrowerName] = useState("")
  const [groweLocationName, setGrowerLocationName] = useState("")
  const cr = props.location.state.cr;
  const reportType = props.location.state.type;
  const history = useHistory();
  const dispatch = useDispatch()
  const textModal = [
    "You are about to delete this order and all of his content.",
    "You are about to delete this Grower and all of his content.",
  ];

  const userRole = useSelector(state => state.authStore.role);
  useEffect(() => {
    // setIsLoading(true);

    let idsFields = [];
    cr.growers.forEach((crg) => {
      let arrayIds = crg.fields.map((field) => {
        return "ids=" + field.field_id;
      });
      idsFields = [...idsFields, ...arrayIds];
    });
    if (idsFields.length > 0) {
      const query = idsFields.join("&");
      if (reportType === "Client") {
        Promise.all([
          RequestServices.getReportsdMultipleFields(query),
         
        ]).then((res) => {
          setReports(res[0]);
        

          let formattedData = { ...cr };
          formattedData.growers.forEach((grower) => {
            grower.fields.forEach((field) => {
              let filteredReports = res[0].filter(
                (report) => report.field_id === field.field_id
              );
              field["reports"] = filteredReports;
            });
          });
          setFullData(formattedData);

          setIsLoading(false);
        });
      } else {
        RequestServices.getManifoldsMultipleFields(query)
          .then((response) => {

            setManifolds(response);
          
            let graphData = {};
            let graphLabels = {};
            idsFields.forEach(item => {
              graphData[item.replace('ids=', '')] = {
                bee_activity: { data: [], title: 'Bees activity', color: 'rgba(29, 151, 108,.7)' },
                cuaja: { data: [], title: 'Fecundation', color: 'rgba(247, 151, 30, .7)' },
                disease: { data: [], title: 'Disease control', color: 'rgba(47, 128, 237, .7)' },
                flowering: { data: [], title: 'Nicking', color: 'rgba(54, 55, 149, .7)' },
                ground_preparation: { data: [], title: 'Soil preparation', color: 'rgba(202,207,214, .7)' },
                harvest: { data: [], title: 'Harvest', color: 'rgba(81,118,100, .7)' },
                irrigation: { data: [], title: 'Irrigation', color: 'rgba(255,236,1, .7)' },
                pests: { data: [], title: 'Pests control', color: 'rgba(81,208,229, .7)' },
                plant_growth: { data: [], title: 'Plants development', color: 'rgba(90,62,56, .7)' },
                population: { data: [], title: 'Population', color: 'rgba(224,188,52, .7)' },
                trasnplant: { data: [], title: 'Transplant', color: 'rgba(3,122,173, .7)' },
                undergrowth: { data: [], title: 'Weeds control', color: 'rgba(251,73,50, .7)' },

              }


            })

            let labelDates = []
            response.forEach(item => {
              item.bee_activity !== -1 && graphData[item.field_id]['bee_activity']['data'].push({ x: item.created_at.slice(0, 10), y: item.bee_activity });
              item.cuaja !== -1 && graphData[item.field_id]['cuaja']['data'].push({ x: item.created_at.slice(0, 10), y: item.cuaja });
              item.disease !== -1 && graphData[item.field_id]['disease']['data'].push({ x: item.created_at.slice(0, 10), y: item.disease });
              item.flowering !== -1 && graphData[item.field_id]['flowering']['data'].push({ x: item.created_at.slice(0, 10), y: item.flowering });
              item.ground_preparation !== -1 && graphData[item.field_id]['ground_preparation']['data'].push({ x: item.created_at.slice(0, 10), y: item.ground_preparation });
              item.harvest !== -1 && graphData[item.field_id]['harvest']['data'].push({ x: item.created_at.slice(0, 10), y: item.harvest });
              item.irrigation !== -1 && graphData[item.field_id]['irrigation']['data'].push({ x: item.created_at.slice(0, 10), y: item.irrigation });
              item.pests !== -1 && graphData[item.field_id]['pests']['data'].push({ x: item.created_at.slice(0, 10), y: item.pests });
              item.plant_growth !== -1 && graphData[item.field_id]['plant_growth']['data'].push({ x: item.created_at.slice(0, 10), y: item.plant_growth });
              item.population !== -1 && graphData[item.field_id]['population']['data'].push({ x: item.created_at.slice(0, 10), y: item.population });
              item.trasnplant !== -1 && graphData[item.field_id]['trasnplant']['data'].push({ x: item.created_at.slice(0, 10), y: item.trasnplant });
              item.undergrowth !== -1 && graphData[item.field_id]['undergrowth']['data'].push({ x: item.created_at.slice(0, 10), y: item.undergrowth });
              labelDates.push(item.created_at.slice(0, 10));
              if (graphLabels[item.field_id]) {

                graphLabels[item.field_id].push(item.created_at.slice(0, 10))
              } else {
                graphLabels[item.field_id] = [item.created_at.slice(0, 10)]
              }

            })
            labelDates = [...new Set(labelDates)]


            configureGraph(graphData, graphLabels)
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
          });
      }
    } else {
      setIsLoading(false);
    }
  }, [])
  const configureGraph = (graphData, graphLabels) => {
    let graphsConfig = {}
    Object.keys(graphData).forEach(fieldId => {

      graphsConfig[fieldId] = {
        labels: [...new Set(graphLabels[fieldId])],
        datasets: [],

      }
      Object.keys(graphData[fieldId]).forEach((item, index) => {

        if (graphData[fieldId][item]['data'].length > 0) {
          graphsConfig[fieldId]['datasets'].push({
            label: graphData[fieldId][item].title,
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#1D976C',
            borderColor: graphData[fieldId][item].color,
            pointBorderWidth: 8,
            pointBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderWidth: 2,
            data: graphData[fieldId][item]['data'],

          })
        }

      })


    });

    setDataGraph(graphsConfig);

  }


  const modalDeleteHandler = (type, id) => {
    if (!modalDelete) {
      setModalType(type);
    }
    if (type === 1) {
      setSelectedId(id);
    }
    setModalDelete(!modalDelete);
  };
  const deleteOrderHandler = () => {
    setIsLoading(true);

    if (modalType === 0) {
      RequestServices.deleteOrder(cr.request_id)
        .then(() => {
          setIsLoading(false);
          history.goBack();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else if (modalType === 1) {
      RequestServices.deleteGrower(selectedId)
        .then(() => {
          setIsLoading(false);
          history.goBack();
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const selectManifoldHandler = (id) => {
    if (id === manifoldVisible) {
      setManifoldVisible(null);

    } else {
      setManifoldVisible(id);

    }
  }

  const modalGrowerHandler = () => {
    setModalGrower(!modalGrower);
  }
  const createGrowerHandler = async () => {
    try {
      setIsLoading(true);
      modalGrowerHandler()
      await dispatch(createGrower(groweName, groweLocationName, cr.request_id))
      setIsLoading(false);

      history.goBack();
    } catch (error) {
      setIsLoading(false);
    }



  }

  const goToCreateManifold = () => {
    history.push({ pathname: "/create-manifold" });
  }
  return (
    <div className={classes.ClientRequestContainer}>
      <h1 className={classes.ContainerTitle}>Order from {cr.client}</h1>

      <div className={classes.topContainer}>
        {cr ? (
          <div className={classes.Summary}>
            <SquareCard>
              <div className={classes.CardContent}>
                <div className={classes.TitleGrower}>
                  <div className={classes.Logo}>
                    <img
                      className={classes.MyImg}
                      src={Summary}
                      alt="summary"
                    />
                  </div>
                  <h3 className={classes.CardTitle}>Summary</h3>
                </div>
                <div className={classes.Details}>
                  <p className={classes.TextItem}>
                    <strong>Specie: </strong>
                    {cr.specie}
                  </p>
                  <p className={classes.TextItem}>
                    <strong>Variety: </strong>
                    {cr.variety}
                  </p>
                  <p className={classes.TextItem}>
                    <strong>Ratio: </strong>
                    {cr.ratio}
                  </p>
                  <p className={classes.TextItem}>
                    <strong>Target kg: </strong>
                    {cr.target_kg}
                  </p>
                  <p className={classes.TextItem}>
                    <strong>Base: </strong>
                    {cr.base}
                  </p>
                  <p className={classes.TextItem}>
                    <strong>Required Surface: </strong>
                    {cr.required_surface}
                  </p>
                  <p className={classes.TextItem}>
                    <strong>Fieldman: </strong>
                    {cr.fieldman_name ? cr.fieldman_name : ''}
                  </p>

                </div>
                {userRole === 'admin' && <div
                  className={classes.delete}
                  onClick={() => {
                    modalDeleteHandler(0);
                  }}
                >
                  <i
                    className={["fas fa-trash", classes.deleteIcon].join(" ")}
                  ></i>
                </div>}
              </div>
            </SquareCard>
          </div>
        ) : null}

        {/* <div className={classes.pdfContainer}>
                    {!isLoading && reportType === 'Client' && <PDFDownloadLink
                        document={<PDFPreviewer name='Camilo' cr={cr} reports={reports} reportsMonth={reportsMonth} />}
                        fileName="report.pdf"
                        style={{
                            textDecoration: "none",
                            padding: "20px",
                            color: "#fff",
                            backgroundColor: "#4A00E0",
                            fontSize: 14,
                            borderRadius: 5

                        }}
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download Pdf"
                        }
                    </PDFDownloadLink >}
                    {!isLoading && reportType === 'Client' && <PDFDownloadLink
                        document={<PDFPreviewerImage name='Camilo' cr={cr} reports={reports} reportsMonth={reportsMonth} />}
                        fileName="report.pdf"
                        style={{
                            marginLeft: 10,
                            textDecoration: "none",
                            padding: "20px",
                            color: "#fff",
                            backgroundColor: "#4A00E0",
                            fontSize: 14,
                            borderRadius: 5

                        }}
                    >
                        {({ blob, url, loading, error }) =>
                            loading ? "Loading document..." : "Download Pdf Images"
                        }
                    </PDFDownloadLink >}
                </div> */}
      </div>
      {reportType === "Client" && !isLoading && cr.growers.length > 0 ? (
        <SquareCard>
          <div className={classes.CardContent}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className={classes.Button}
              table="table-to-xls"
              filename="tablexls"
              sheet="tablexls"
              buttonText="Download as XLS"
            />
            {userRole === 'admin' && <Button btnType='Save' clicked={modalGrowerHandler} isDisabled={false}>Create Grower</Button>}
            <TableBig data={[fullData]} myId="table-to-xls" />
          </div>
        </SquareCard>
      ) : null}

      {reportType === "Client" && !isLoading && cr.growers.length > 0
        ? cr.growers.map((crg) => (
          <SquareCard key={crg.grower_id}>
            <div className={classes.CardContent}>
              <div>
                <div className={classes.TitleGrower}>
                  <div className={classes.Logo}>
                    <img
                      className={classes.MyImg}
                      src={FarmerImg}
                      alt="farmer"
                    />
                  </div>
                  <h3 className={classes.CardTitle}>
                    {crg.name}, {crg.location}
                  </h3>
                </div>
                {crg.fields.length > 0
                  ? crg.fields.map((crgf) => (
                    <div key={crgf.field_id}>
                      <div className={classes.TitleField}>
                        <div className={classes.Logo}>
                          <img
                            className={classes.MyImg}
                            src={Farm}
                            alt="field"
                          />
                        </div>
                        <div>
                          <h3 className={classes.CardSubTitle}>{crgf.name}</h3>
                        </div>

                        {userRole === 'admin' && <Button btnType='Blue' clicked={goToCreateManifold} isDisabled={false}>Create manifold</Button>}

                      </div>
                      {/* {crgf.reports.length > 0 && <div>
                        {crgf.reports[0].report_surface && <div>
                          <p className={classes.reportName}>
                            Report Surface
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_surface.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_surface.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_surface.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_surface.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_surface.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_plants_m && <div>
                          <p className={classes.reportName}>
                            Report Plants/m
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_plants_m.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_plants_m.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_plants_m.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_plants_m.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_plants_m.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_transplanting_beginning && <div>
                          <p className={classes.reportName}>
                            Report Transplanting Beginning
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_transplanting_beginning.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_transplanting_beginning.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_transplanting_beginning.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_transplanting_beginning.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_transplanting_beginning.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_transplanting_finish && <div>
                          <p className={classes.reportName}>
                            Report Transplanting Finish
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_transplanting_finish.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_transplanting_finish.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_transplanting_finish.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_transplanting_finish.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_transplanting_finish.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_commercial_stage && <div>
                          <p className={classes.reportName}>
                            Report Commercial Stage
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_commercial_stage.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_commercial_stage.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_commercial_stage.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_commercial_stage.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_commercial_stage.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_cut && <div>
                          <p className={classes.reportName}>
                            Report Cut/Pruning
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_cut.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_cut.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_cut.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_cut.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_cut.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_bolting_beginning && <div>
                          <p className={classes.reportName}>
                            Report Beginning of Bolting
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_bolting_beginning.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_bolting_beginning.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_bolting_beginning.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_bolting_beginning.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_bolting_beginning.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_flowering && <div>
                          <p className={classes.reportName}>
                            Report Flowering 1%
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_flowering.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_flowering.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_flowering.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_flowering.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_flowering.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_flowering_50 && <div>
                          <p className={classes.reportName}>
                            Report Flowering 50%
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_flowering_50.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_flowering_50.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_flowering_50.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_flowering_50.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_flowering_50.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_flowering_0 && <div>
                          <p className={classes.reportName}>
                            Report Flowering -1%
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_flowering_0.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_flowering_0.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_flowering_0.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_flowering_0.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_flowering_0.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_nicking && <div>
                          <p className={classes.reportName}>
                            Report Nicking
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_nicking.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_nicking.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_nicking.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_nicking.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_nicking.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_staking && <div>
                          <p className={classes.reportName}>
                            Report Staking
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_staking.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_staking.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_staking.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_staking.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_staking.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_beehives && <div>
                          <p className={classes.reportName}>
                            Report Beehives
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_beehives.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_beehives.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_beehives.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_beehives.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_beehives.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_male_elimination && <div>
                          <p className={classes.reportName}>
                            Report Male Elimination
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_male_elimination.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_male_elimination.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_male_elimination.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_male_elimination.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_male_elimination.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_last_irrigation && <div>
                          <p className={classes.reportName}>
                            Report Last Irrigation
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_last_irrigation.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_last_irrigation.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_last_irrigation.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_last_irrigation.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_last_irrigation.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_harvest && <div>
                          <p className={classes.reportName}>
                            Report Harvest
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_harvest.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_harvest.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_harvest.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_harvest.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_harvest.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                        {crgf.reports[0].report_desiccant && <div>
                          <p className={classes.reportName}>
                            Report Desiccant
                          </p>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_desiccant.url_general_sm} title="General picture" landscape />
                            <ImageCard src={crgf.reports[0].report_desiccant.url_female_sm} title="Female picture" />
                          </div>
                          <div className={classes.img_container}>
                            <ImageCard src={crgf.reports[0].report_desiccant.url_male_1_sm} title="Male picture 1" />
                            <ImageCard src={crgf.reports[0].report_desiccant.url_male_2_sm} title="Male picture 2" />
                            <ImageCard src={crgf.reports[0].report_desiccant.url_male_3_sm} title="Male picture 3" />
                          </div>


                        </div>}
                      </div>} */}

                    </div>
                  ))
                  : null}
              </div>
              {userRole === 'admin' && <div
                className={classes.delete}
                onClick={() => {
                  modalDeleteHandler(1, crg.grower_id);
                }}
              >
                <i
                  className={["fas fa-trash", classes.deleteIcon].join(" ")}
                ></i>
              </div>}
            </div>
          </SquareCard>
        ))
        : null}

      {!isLoading && cr.growers.length > 0
        ? cr.growers.map((crg) => (
          <SquareCard key={crg.grower_id}>
            <div className={classes.CardContent}>
              <div>
                <div className={classes.TitleGrower}>
                  <div className={classes.Logo}>
                    <img
                      className={classes.MyImg}
                      src={FarmerImg}
                      alt="farmer"
                    />
                  </div>
                  <h3 className={classes.CardTitle}>
                    {crg.name}, {crg.location}
                  </h3>
                </div>
                {crg.fields.length > 0
                  ? crg.fields.map((crgf) => (
                    <div key={crgf.field_id}>
                      <div className={classes.TitleField}>
                        <div className={classes.Logo}>
                          <img
                            className={classes.MyImg}
                            src={Farm}
                            alt="field"
                          />
                        </div>
                        <div>
                          <h3>{crgf.name}</h3>
                          {/* <h4>{variety.length > 0 && variety.find(vt => vt.variety_id === crgf.variety)['variety_name']}</h4> */}
                        </div>
                      </div>
                      {(reportType === "Normal" && dataGraph[crgf.field_id].datasets.length > 0) && <div className={classes.graphContainer}>
                        <p className={classes.graphTitle}>Manifold Scores</p>

                        <Line data={dataGraph[crgf.field_id]} options={{

                          scales: {
                            yAxes: [{
                              ticks: {
                                max: 8,
                                min: 0,
                                stepSize: 1
                              }
                            }]
                          }

                        }} />



                      </div>}
                      {reportType === "Client" && reports && (
                        <Aux>
                          <div className={classes.tableContainer}>
                            <table className={classes.table}>
                              <tbody>
                                <tr>
                                  <td>Estimated Surface</td>
                                  <td>-</td>
                                </tr>
                                <tr>
                                  <td>Real Surface</td>
                                  <td>-</td>
                                </tr>
                                <tr>
                                  <td>Plants required</td>
                                  <td>-</td>
                                </tr>
                                <tr>
                                  <td>Plants real</td>
                                  <td>-</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <Table
                            data={reports.filter(
                              (report) => report.field_id === crgf.field_id
                            )}
                          />
                        </Aux>
                      )}

                      {reportType === "Normal" &&
                        manifolds &&
                        manifolds
                          .filter((mani) => mani.field_id === crgf.field_id)
                          .map((mani) => (
                            <div key={mani.manifold_id}>
                              <div className={[classes.barManifold, mani.manifold_id === manifoldVisible && classes.barSelected].join(' ')} onClick={() => { selectManifoldHandler(mani.manifold_id) }} >
                                <p className={classes.barManifoldTitle}>Manifold of {mani.created_at.slice(0, 10)}</p>
                              </div>
                              {mani.manifold_id === manifoldVisible && <div
                                className={classes.ManifoldContainer}

                              >
                                <div className={classes.ManifoldTable}>
                                  <div className={classes.Column}>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Date
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.created_at.slice(0, 10)}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Soil preparation
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.ground_preparation === -1 ? '-' : mani.ground_preparation}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Transplant
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.trasnplant === -1 ? '-' : mani.trasnplant}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Population
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.population === -1 ? '-' : mani.population}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Weeds control
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.undergrowth === -1 ? '-' : mani.undergrowth}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Pests control
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.pests === -1 ? '-' : mani.pests}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Plants development
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.plant_growth === -1 ? '-' : mani.plant_growth}
                                      </div>
                                    </div>
                                  </div>
                                  <div className={classes.Column}>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Irrigation
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.irrigation === -1 ? '-' : mani.irrigation}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Disease control
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.disease === -1 ? '-' : mani.disease}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Bees activity
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.bee_activity === -1 ? '-' : mani.bee_activity}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Nicking
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.flowering === -1 ? '-' : mani.flowering}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Fecundation
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.cuaja === -1 ? '-' : mani.cuaja}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Harvest
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.harvest === -1 ? '-' : mani.harvest}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Last recommendation fulfillment
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.recommendations_compliance === 1
                                          ? "Done"
                                          : "Pending"}
                                      </div>
                                    </div>
                                    <div className={classes.RowManifold}>
                                      <div className={classes.LabelManifold}>
                                        Treatment
                                      </div>
                                      <div
                                        className={classes.ContentManifold}
                                      >
                                        {mani.comment}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className={classes.manifoldPictures}>

                                  {/* {(mani.general_picture_small || mani.female_sm) && <div className={classes.img_container}>
                                    {mani.general_picture_small && <ImageCard src={mani.general_picture_small} title="General picture" landscape />}
                                    {mani.female_sm && <ImageCard src={mani.female_sm} title="Female picture" />}
                                  </div>}
                                  {(mani.male_1_sm || mani.male_2_sm || mani.male_3_sm) && <div className={classes.img_container}>
                                    {mani.male_1_sm && <ImageCard src={mani.male_1_sm} title="Male picture 1" />}
                                    {mani.male_2_sm && <ImageCard src={mani.male_2_sm} title="Male picture 2" />}
                                    {mani.male_3_sm && <ImageCard src={mani.male_3_sm} title="Male picture 3" />}
                                  </div>}

                                  {(mani.extra_sm) && <div className={classes.img_container}>
                                    {mani.extra_sm && <ImageCard src={mani.extra_sm} title="Extra picture" />}

                                  </div>} */}

                                  {(mani.general_picture_small || mani.female) && <div className={classes.img_container}>
                                    {mani.general_picture_small && <ImageCard src={mani.general_picture_small} title="General picture" landscape date={mani.created_at}/>}
                                    {mani.female && <ImageCard src={mani.female_sm} title="Female picture"  date={mani.created_at}/>}
                                  </div>}
                                  {(mani.male_1 || mani.male_2 || mani.male_3) && <div className={classes.img_container}>
                                    {mani.male_1 && <ImageCard src={mani.male_1_sm} title="Male picture 1"  date={mani.created_at}/>}
                                    {mani.male_2 && <ImageCard src={mani.male_2_sm} title="Male picture 2"  date={mani.created_at}/>}
                                    {mani.male_3 && <ImageCard src={mani.male_3} title="Male picture 3"  date={mani.created_at}/>}
                                  </div>}

                                  {(mani.extra_sm) && <div className={classes.img_container}>
                                    {mani.extra_sm && <ImageCard src={mani.extra_sm} title="Extra picture"  date={mani.created_at}/>}

                                  </div>}
                                </div>
                              </div>
                              }</div>
                          ))}
                    </div>
                  ))
                  : null}
              </div>
            </div>
          </SquareCard>
        ))
        : null}

      {isLoading ? <Loader /> : null}
      {
        <Modal show={modalDelete} modalClosed={modalDeleteHandler}>
          <div className={classes.deleteModal}>
            <p className={classes.deleteText}>{textModal[modalType]}</p>
            <div>
              <Button btnType="Succes" clicked={modalDeleteHandler}>
                GO BACK
              </Button>
              <Button btnType="Danger" clicked={deleteOrderHandler}>
                DELETE
              </Button>
            </div>
          </div>
        </Modal>
      }

      <Modal show={modalGrower} modalClosed={modalGrowerHandler}>
        <div >
          <p className={classes.title}>Creating Grower</p>
          <p className={classes.TextItem}>Grower name</p>
          <input className={classes.inputElement} value={groweName} onChange={(e) => { setGrowerName(e.target.value) }} />
          <p className={classes.TextItem}>Grower location name</p>
          <input className={classes.inputElement} value={groweLocationName} onChange={(e) => { setGrowerLocationName(e.target.value) }} />
          <div className={classes.buttonContainer}>
            <Button btnType='Soft' clicked={modalGrowerHandler}>Cancel</Button>

            <Button btnType='Save' clicked={createGrowerHandler} isDisabled={(groweName.length === 0 || groweLocationName.length === 0)}>Create</Button>

          </div>
        </div>
      </Modal>
      {/* <Modal show={modalShow} modalClosed={modalHandler}>
                <div className={classes.imgContainer}>
                    <ImageHolder image={images[0]} />
                    <ImageHolder image={images[1]} />
                    <ImageHolder image={images[2]} />
                </div>
            </Modal>
            <Modal show={modalShowWarning} modalClosed={modalHandlerWarning}>
                <div className={classes.ModalWarn}> 
                <div className={classes.imgContainer}>
                    <ImageHolder image={imageWarning} />
                    
                </div>
                <div className={classes.Comment}>
                    There are some problems with this field.
                </div>
                </div>
                
            </Modal> */}
    </div>
  );
};

export default ClientOrderDetailScreen;
