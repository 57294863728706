import React from 'react';
import classes from './ImageCard.module.css';
const ImageCard = (props) => {

    return (
        <div className={props.landscape ? classes.card_landscape: classes.card}>
            <img src={props.src} alt="image_card" className={props.landscape ? classes.img_card_landscape: classes.img_card}/>
            <div className={classes.waterMark}>
                {props.date ? <p>{props.date.slice(0, 10)}</p>: null}
            </div>
                <div className={classes.container}>
                    
                    <p>{props.title}</p>
                    
                </div>
            </div>
    );
}

export default ImageCard;