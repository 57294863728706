import React from 'react';
import classes from './TableReports.module.css';

const TableReports = props => {
    const { data } = props;
    const report = data[0];
    const reportsDates = [
        
        { id: 2, title: 'Sowing Date',  state: ['sowing_female', 'sowing_male_1', 'sowing_male_2', 'sowing_male_3'] },
        { id: 4, title: 'Transplanting Init',  state: ['transplanting_female', 'transplanting_male_1', 'transplanting_male_2', 'transplanting_male_3'] },
        { id: 5, title: 'Transplanting Finish',  state: ['transplanting_f_female', 'transplanting_f_male_1', 'transplanting_f_male_2', 'transplanting_f_male_3'] },
        { id: 6, title: 'Commercial stage',  state: ['commercial_female', 'commercial_male_1', 'commercial_male_2', 'commercial_male_3'] },
        { id: 7, title: 'Cut/pruning',  state: ['cut_female', 'cut_male_1', 'cut_male_2', 'cut_male_3'] },
        { id: 8, title: 'Begining of bolting',  state: ['bolting_female', 'bolting_male_1', 'bolting_male_2', 'bolting_male_3'] },
        { id: 9, title: 'Flowering 1',  state: ['flowering_1_female', 'flowering_1_male_1', 'flowering_1_male_2', 'flowering_1_male_3'] },
        { id: 10, title: 'Flowering 50',  state: ['flowering_5_female', 'flowering_5_male_1', 'flowering_5_male_2', 'flowering_5_male_3'] },
        { id: 11, title: 'Flowering -1',  state: ['flowering_0_female', 'flowering_0_male_1', 'flowering_0_male_2', 'flowering_0_male_3'] },
        { id: 12, title: 'Nicking Adjust',  state: ['nicking_female', 'nicking_male_3', 'nicking_male_2', 'nicking_male_1'] }]
    
    return (
       data && data.length > 0 ? <div className={classes.tableContainer}>
            <table className={classes.table}>
                <thead>
                    <tr>
                        <th>Report</th>
                        <th>Female</th>
                        <th>Male 1</th>
                        <th>Male 2</th>
                        <th>Male 3</th>

                    </tr>
                </thead>


                <tbody>

                    {reportsDates.map(item=>(
                        <tr key={item.id}>
                            <td>{item.title}</td>
                            <td>{report[item.state[0]] ? report[item.state[0]].slice(0,10): '-'}</td>
                            <td>{report[item.state[1]] ? report[item.state[1]].slice(0,10): '-'}</td>
                            <td>{report[item.state[2]] ? report[item.state[2]].slice(0,10): '-'}</td>
                            <td>{report[item.state[3]] ? report[item.state[3]].slice(0,10): '-'}</td>
                        </tr>
                    ))}
                    
                    {/* {props.data.map(item => (
                        <tr key={item.report_id}>
                            <td>{item.report_type}</td>
                            <td>{item.female.slice(0,10)}</td>
                            <td>{item.male_1.slice(0,10)}</td>
                            <td>{item.male_2.slice(0,10)}</td>
                            <td>{item.male_3.slice(0,10)}</td>
                            <td><Button btnType='Succes' clicked={()=>{props.btnClicked([item.image_small_1,item.image_small_2,item.image_small_3])}}>Pictures</Button></td>
                        </tr>
                    ))} */}

                    {/* {posts.map(post => (
                                <tr key={post.id}>
                                    <td>{post.name}</td>
                                    <td>{post.description}</td>
                                    <td><Button btnType='Danger' clicked={() => deletePostHandler(post.id)}>delete</Button></td>
                                </tr>
                            ))} */}


                </tbody>



            </table>

        </div> : null
    );

}

export default TableReports;