import React from 'react';
import Logo from './plant.png';
import { useSelector } from 'react-redux';
import NavigationItem from './NavigationItem/NavigationItem';
import classes from './SideBarNavigation.module.css';

const SideBarNavigation = props => {
    const userRole = useSelector(state => state.authStore.role);
    return (
        <div className={classes.SideBarContainer}>
            <div className={classes.TopContainer}>
                <div className={classes.Logo}>
                    <img className={classes.MyImg} src={Logo}/>
                </div>
                <div className={classes.User}>Pinto Piga Web</div>
            </div>
            <nav>
            <div className={classes.NavLinksWrapper}>
                    
                   
                    {userRole === 'admin' ?
                    <>
                    <NavigationItem path='/' iconName='fa fa-home' title='Client Orders'/>
                    <NavigationItem path='/reports' iconName='fas fa-table' title='Reports'/>
                    <NavigationItem path='/stats' iconName='far fa-eye' title='Activity'/>
                    <NavigationItem path='/admin' iconName='fa fa-book' title='Client admin'/>
                    <NavigationItem path='/species' iconName='fa fa-book' title='Species admin'/>
                    <NavigationItem path='/varities' iconName='fa fa-book' title='Varieties admin'/>
                    <NavigationItem path='/ratios' iconName='fa fa-book' title='Ratios admin'/>
                    </>: <>
                    
                    <NavigationItem path='/' iconName='fa fa-home' title='Client Orders'/>
                    <NavigationItem path='/stats' iconName='fa fa-home' title='Stats'/>

                    </>}

            </div>
            </nav>
        </div>)
}

export default SideBarNavigation