export default class Field {
    constructor(id, name, locationName, clientId, jobNumber, growerId, specieId, varietyId, ratioId, code, stimatedSup, realSup, baseClient, baseGrower, fieldmanId, lat, lng) {
        this.id = id;
        this.name = name;
        this.locationName = locationName;
        this.clientId = clientId;
        this.jobNumber = jobNumber;
        this.growerId = growerId;
        this.specieId = specieId;
        this.varietyId = varietyId;
        this.ratioId = ratioId;
        this.code = code;
        this.stimatedSup = stimatedSup;
        this.realSup = realSup;
        this.baseClient = baseClient;
        this.baseGrower = baseGrower;
        this.fieldmanId = fieldmanId;
        this.lat = lat;
        this.lng = lng;
    }
}