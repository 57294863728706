import { SET_ORDERS_FORMATTED, SET_SEASON } from "./orders-actions";
const initalState = {
  ordersFormatted: null,
  season: { value: 4, name: "2023-2024" },
};

export default (state = initalState, action) => {
  switch (action.type) {
    case SET_ORDERS_FORMATTED:
      return { ...state, ordersFormatted: action.payload };
    case SET_SEASON:
      return { ...state, season: action.payload };
  }
  return state;
};
