import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReduxThunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { BrowserRouter} from 'react-router-dom';
import mainReducer from './store/reducers';
import authReducer from './store/auth/auth-reducers';
import ordersReducer from 'store/orders/orders-reducer';
const rootReducer = combineReducers({
  mainStore: mainReducer,
  authStore: authReducer,
  ordersStore: ordersReducer
})
const store = createStore(rootReducer, applyMiddleware(ReduxThunk));

const app = (
  <Provider store={store}>
    <BrowserRouter>
    
      <App />
      
    </BrowserRouter>
  </Provider>)

ReactDOM.render(
  <React.StrictMode>
    {app}
  </React.StrictMode>,
  document.getElementById('root')
);


serviceWorker.unregister();
