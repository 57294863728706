import React from 'react';

import classes from './FloatingButton.module.css';

const FloatingButton = props =>{

    return(
        <button onClick={props.clicked} className={[classes.Button, classes[props.btnType]].join(' ')}>{props.children}</button>
        );

}

export default FloatingButton;