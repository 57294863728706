import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://www.zeedgo.xyz'
});


//instance.defaults.headers.common["Access-Control-Allow-Origin"] = '*';

// instance.interceptors.request...

export default instance;