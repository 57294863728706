import React, { useState, useEffect, useReducer, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import classes from './CreateManifoldScreen.module.css';

import Dropdown from '../../components/UI/Dropdown/Dropdown';
import SquareCard from '../../components/UI/Card/SquareCard';
import Loader from '../../components/UI/loader/Loader';
import Button from '../../components/UI/Button/Button';
import ImageCard from 'components/UI/ImageCard/ImageCard';
const UPDATE_FORM = 'UPDATE_FORM';

const formReducer = (state, action) => {

    if (action.type === UPDATE_FORM) {

        const updatedValues = {
            ...state.inputsValues,
            [action.input]: action.value
        };

        const updatedValidities = {
            ...state.inputsValids,
            [action.input]: action.valid
        };


        let completeFormValid = true;
        for (const key in updatedValidities) {
            completeFormValid = completeFormValid && updatedValidities[key];
        }

        return {
            inputsValues: updatedValues,
            inputsValids: updatedValidities,
            formIsValid: completeFormValid
        }
    }
    return state;
}




const CreateManifoldScreen = props => {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [generalPic, setGeneralPic] = useState()
    const [femalePic, setFemalePic] = useState()
    const [malePicOne, setMalePicOne] = useState()
    const [malePicTwo, setMalePicTwo] = useState()
    const [malePicThree, setMalePicThree] = useState()
    const [extraPic, setExtraPic] = useState()
    const points = [{ value: -1, name: "-" }, { value: 1, name: "1" }, { value: 2, name: "2" }, { value: 3, name: "3" }, { value: 4, name: "4" }, { value: 5, name: "5" }, { value: 6, name: "6" }, { value: 7, name: "7" }]
    const recom = [{ value: -1, name: "-" }, { value: 1, name: "Done" }, { value: 2, name: "Pending" },]
    const [formState, dispatchForm] = useReducer(formReducer,
        {
            inputsValues: {
                treatment: '',
                extraComment:'',
                mail:'',
                soil: { value: -1, name: 'Select score' },
                transplant: { value: -1, name: 'Select score' },
                population: { value: -1, name: 'Select score' },
                weedsControl: { value: -1, name: 'Select score' },
                pest: { value: -1, name: 'Select score' },
                plantsDevelopment: { value: -1, name: 'Select score' },
                irrigation: { value: -1, name: 'Select score' },
                diseasesControl: { value: -1, name: 'Select score' },
                bees: { value: -1, name: 'Select score' },
                nicking: { value: -1, name: 'Select score' },
                fecundation: { value: -1, name: 'Select score' },
                harvest: { value: -1, name: 'Select score' },
                recommendation: { value: -1, name: 'Select state' },
            },
            inputsValids: {
                base: false,
                required: false,


                client: false,
                specie: false,
                ratio: false,
                variety: false,
                fieldman: false
            },
            formIsValid: false

        });

    const inputChangeHandler = useCallback((event, identifier) => {

        let valid = true;
        if (event.target.value.trim().length === 0) {
            valid = false;
        }
        dispatchForm({ type: UPDATE_FORM, input: identifier, value: event.target.value, valid: valid });

    }, [dispatchForm])

    const dropdownHandler = useCallback((value, name, identifier) => {

        dispatchForm({ type: UPDATE_FORM, input: identifier, value: { value: value, name: name }, valid: true });

    }, [dispatchForm])

    useEffect(() => {



    }, []);


    const fileHandler = (e) => {
        if (e.target.files[0]) {
            switch (e.target.name) {
                case "generalPic":
                    setGeneralPic(URL.createObjectURL(e.target.files[0]))
                    break;
                case "femalePic":
                    setFemalePic(URL.createObjectURL(e.target.files[0]))
                    break;
                case "malePicOne":
                    setMalePicOne(URL.createObjectURL(e.target.files[0]))
                    break;
                case "malePicTwo":
                    setMalePicTwo(URL.createObjectURL(e.target.files[0]))
                    break;
                case "malePicThree":
                    setMalePicThree(URL.createObjectURL(e.target.files[0]))
                    break;
                case "extraPic":
                    setExtraPic(URL.createObjectURL(e.target.files[0]))
                    break;

                default:
                    break;
            }

        }

    }




    return (isLoading ? <Loader /> :
        <div className={classes.manifoldScreen}>
            <div className={classes.Holder}>

                <SquareCard Bordered="Blue">
                    <div className={classes.container}>
                        <h1 className={classes.mainTitle}>Create Manifold</h1>

                        <div className={classes.formContainer}>
                            <form className={classes.form}>

                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Soil Preparation</p>
                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.soil} myId="client_id" identifier="soil" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Transplant</p>
                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.transplant} myId="client_id" identifier="transplant" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Population</p>
                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.population} myId="client_id" identifier="population" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Weeds Control</p>

                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.weedsControl} myId="client_id" identifier="weedsControl" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Pests control</p>

                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.pest} myId="client_id" identifier="pest" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Plants Development</p>
                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.plantsDevelopment} myId="client_id" identifier="plantsDevelopment" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Irrigation</p>

                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.irrigation} myId="client_id" identifier="irrigation" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Diseases Control</p>

                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.diseasesControl} myId="client_id" identifier="diseasesControl" />

                                </div>

                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Bees Activity</p>

                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.bees} myId="client_id" identifier="bees" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Nicking</p>

                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.nicking} myId="client_id" identifier="nicking" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Fecundation</p>

                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.fecundation} myId="client_id" identifier="fecundation" />

                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Harvest Activities</p>

                                    <Dropdown data={points} selected={dropdownHandler} value={formState.inputsValues.harvest} myId="client_id" identifier="harvest" />

                                </div>

                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Last Recommendation fulfillment</p>

                                    <Dropdown data={recom} selected={dropdownHandler} value={formState.inputsValues.recommendation} myId="client_id" identifier="recommendation" />

                                </div>
                                <input placeholder='Treatment' className={classes.inputElement} onChange={(event) => inputChangeHandler(event, 'treatment')} value={formState.inputsValues.treatment} />
                                {/* <input placeholder='Base' className={classes.inputElement} onChange={(event) => inputChangeHandler(event, 'base')} value={formState.inputsValues.base} />
                                
                                <input placeholder='Required surface' className={classes.inputElement} onChange={(event) => inputChangeHandler(event, 'required')} value={formState.inputsValues.required} />
                                
                                <p className={classes.inputElement} >{(formState.inputsValues.base && formState.inputsValues.required)? `Target ${formState.inputsValues.base * formState.inputsValues.required} kg`: 'Target 0 kg'}</p>
                                
     */}

                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >General Picture</p>
                                    <input
                                        id="generalPic"
                                        name="generalPic"
                                        type="file"

                                        onChange={(event) => fileHandler(event)}
                                        accept=".png,.jpg"
                                        className={classes.filePicker}
                                    />
                                    <label htmlFor="generalPic" className={classes.labelPicker}>
                                        Select image
                                    </label>
                                </div>
                                <div className={classes.imgPreviewContainer}>
                                    {generalPic && <ImageCard src={generalPic} title="General picture" />}
                                </div>

                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Female Picture</p>
                                    <input
                                        id="femalePic"
                                        name="femalePic"
                                        type="file"

                                        onChange={(event) => fileHandler(event)}
                                        accept=".png,.jpg"
                                        className={classes.filePicker}
                                    />
                                    <label htmlFor="femalePic" className={classes.labelPicker}>
                                        Select image
                                    </label>
                                </div>
                                <div className={classes.imgPreviewContainer}>
                                    {femalePic && <ImageCard src={femalePic} title="Female picture" />}
                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Male Picture 1</p>
                                    <input
                                        id="malePicOne"
                                        name="malePicOne"
                                        type="file"

                                        onChange={(event) => fileHandler(event)}
                                        accept=".png,.jpg"
                                        className={classes.filePicker}
                                    />
                                    <label htmlFor="malePicOne" className={classes.labelPicker}>
                                        Select image
                                    </label>
                                </div>
                                <div className={classes.imgPreviewContainer}>
                                    {malePicOne && <ImageCard src={malePicOne} title="Male Picture 1" />}
                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Male Picture 2</p>
                                    <input
                                        id="malePicTwo"
                                        name="malePicTwo"
                                        type="file"

                                        onChange={(event) => fileHandler(event)}
                                        accept=".png,.jpg"
                                        className={classes.filePicker}
                                    />
                                    <label htmlFor="malePicTwo" className={classes.labelPicker}>
                                        Select image
                                    </label>
                                </div>
                                <div className={classes.imgPreviewContainer}>
                                    {malePicTwo && <ImageCard src={malePicTwo} title="Male Picture 2" />}
                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Male Picture 3</p>
                                    <input
                                        id="malePicThree"
                                        name="malePicThree"
                                        type="file"

                                        onChange={(event) => fileHandler(event)}
                                        accept=".png,.jpg"
                                        className={classes.filePicker}
                                    />
                                    <label htmlFor="malePicThree" className={classes.labelPicker}>
                                        Select image
                                    </label>
                                </div>
                                <div className={classes.imgPreviewContainer}>
                                    {malePicThree && <ImageCard src={malePicThree} title="Male Picture 3" />}
                                </div>
                                <div className={classes.selectorHolder}>
                                    <p className={classes.label} >Extra Picture</p>
                                    <input
                                        id="extraPic"
                                        name="extraPic"
                                        type="file"

                                        onChange={(event) => fileHandler(event)}
                                        accept=".png,.jpg"
                                        className={classes.filePicker}
                                    />
                                    <label htmlFor="extraPic" className={classes.labelPicker}>
                                        Select image
                                    </label>
                                </div>
                                <div className={classes.imgPreviewContainer}>
                                    {extraPic && <ImageCard src={extraPic} title="Extra Picture" />}
                                </div>
                                {extraPic && <input placeholder='Extra comment' className={classes.inputElement} onChange={(event) => inputChangeHandler(event, 'extraComment')} value={formState.inputsValues.extraComment} />}
                                <input placeholder='Email' className={classes.inputElement} onChange={(event) => inputChangeHandler(event, 'mail')} value={formState.inputsValues.mail} />

                            </form>
                            <div className={classes.buttonContainer}>
                                <Button btnType='Save' clicked={() => { }} isDisabled={false}>CREATE</Button>

                            </div>
                        </div>
                    </div>
                </SquareCard>
            </div>
        </div>)
}

export default CreateManifoldScreen;