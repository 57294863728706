import React, { useEffect } from 'react';
import {  Route, Switch, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import './App.css';
import SideBarNavigation from './components/Navigation/SideBarNavigation/SideBarNavigation';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import AdminScreen from './screens/AdminScreen/AdminScreen';
import CreateClientOrderScreen from './screens/CreateClientOrder/CreateClientOrderScreen';
import HomeScreen from './screens/HomeScreen/HomeScreen';
import ClientOrderDetailScreen from './screens/ClientOrderDetail/ClientOrderDetailScreen';
import AdminRatiosScreen from './screens/AdminRatios/AdminRatiosScreen';
import ReportScreen from './screens/ReportScreen/ReportScreen';
import AdminSpeciesScreen from './screens/AdminSpecies/AdminSpeciesScreen';
import AdminVarietiesScreen from './screens/AdminVarities/AdminVarietiesScreen';
import CreateManifoldScreen from 'screens/CreateManifold/CreateManifoldScreen';
import StatsScreen from 'screens/StatsScreen/StatsScreen';
import * as authActions from './store/auth/auth-actions';
import Aux from './hoc/MyAux';



const App = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    console.log("run")
    dispatch(authActions.authCheckState());
  },[])
  const authenticated = useSelector(state => state.authStore.authenticated);
  const userRole = useSelector(state => state.authStore.role);
  let routes = (
    <Aux>


      <Switch>
        <Route path='/login' component={LoginScreen} />
        <Redirect to="/login" />

      </Switch>
    </Aux>
  )
  if (authenticated && userRole ==='admin') {
    routes = (
      <Aux>

        <SideBarNavigation />
        <Switch>

          <Route path='/' exact component={HomeScreen} />
          <Route path='/detailclient' component={ClientOrderDetailScreen} />
          <Route path='/admin' component={AdminScreen} />
          <Route path='/createorder' component={CreateClientOrderScreen} />
          <Route path='/create-manifold' component={CreateManifoldScreen} />
          <Route path='/reports' component={ReportScreen} />
          <Route path='/stats' component={StatsScreen} />
          <Route path='/ratios' component={AdminRatiosScreen} />
          <Route path='/species' component={AdminSpeciesScreen} />
          <Route path='/varities' component={AdminVarietiesScreen} />
          <Redirect to="/" />

        </Switch>
      </Aux>
    )
  }
  if (authenticated && userRole ==='fieldman') {
    routes = (
      <Aux>

        <SideBarNavigation />
        <Switch>

          <Route path='/' exact component={HomeScreen} />
          <Route path='/detailclient' component={ClientOrderDetailScreen} />
          <Route path='/createorder' component={CreateClientOrderScreen} />
          <Route path='/create-manifold' component={CreateManifoldScreen} />
          
          <Redirect to="/" />

        </Switch>
      </Aux>
    )
  }
  return (

    <div className="AppContainer">
      {routes}
    </div>

  );
}
export default App;
