export default class Report {
    constructor(reportId, fieldId, reportType, active, female, male1, male2, male3, imageLg1, imageLg2, imageLg3, imageSm1, imageSm2, imageSm3){
        this.reportId = reportId;
        this.fieldId = fieldId;
        this.reportType =reportType;
        this.active = active;
        this.female = female;
        this.male1 = male1;
        this.male2 = male2;
        this.male3 = male3;
        this.imageLg1 = imageLg1;
        this.imageLg2 = imageLg2;
        this.imageLg3 = imageLg3;
        this.imageSm1 = imageSm1;
        this.imageSm2 = imageSm2;
        this.imageSm3 = imageSm3;

    }
}