import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect'

import classes from './AdminRatiosScreen.module.css';
import Loader from '../../components/UI/loader/Loader';
import Modal from '../../components/UI/Modal/Modal';
import Button from '../../components/UI/Button/Button';
import * as actions from '../../store/actions';
import ModalDelete from 'components/UI/Modal/ModalDelete';


const AdminRatiosScreen = props => {
    const [name, setName] = useState('');
    const [search, setSearch] = useState('');
    const [modalShow, setModalShow] = useState(false);
    const [modalDeleteShow, setModalDeleteShow] = useState(false);
    const [selected, setSelected] = useState();
    const [deleteSelected, setDeleteSelected] = useState();
    const [isLoading, setIsloading] = useState(false);
    const [modalType, setModalType] = useState('edit');
    const [updatedValue, setUpdatedValue] = useState('');
    const posts = useSelector(state => state.mainStore.ratios);
   
    const dispatch = useDispatch();


    const loadClients = useCallback(async () => {
        try {
            await dispatch(actions.getRatios());
        } catch (err) {

        }
    }, [dispatch]);

    const addPostHandler = async (name) => {

        try {
            setIsloading(true);
            await dispatch(actions.addRatio(name));
        } catch (error) {

        }
        setIsloading(false);
        setName('');
        
    }
    // const deletePostHandler = async (id) => {

    //     try {
    //         setIsloading(true);
    //         await dispatch(actions.deletePost(id));
    //     } catch (error) {

    //     }
    //     setIsloading(false);
    // }
    const modalHandler = ()=>{
        setModalShow(!modalShow);
    }

    useEffect(() => {
        console.log('Fetching');
        setIsloading(true);
        loadClients().then(() => {
            setIsloading(false);
        });
    }, [dispatch, loadClients]);

    const filterPostHanlder = (value) => {
        dispatch(actions.filterPosts(value));
    }

    const inputSearchHandler = (event) => {
        setSearch(event.target.value);
    }
    const inputNameHandler = (event) => {
        setName(event.target.value);
    }

    const modalDeleteHandler = () => {
        setModalDeleteShow(!modalDeleteShow);
    }
    const selectDeleteHandler = (item) => {
        setDeleteSelected(item)
        setModalDeleteShow(true);
    }
    const selectEditHandler = (item) => {
        setSelected(item);
        setUpdatedValue(item.name);
        setModalType('edit');
        modalHandler()
    }
    const deleteItem = async () => {
        setModalDeleteShow(false);
        setIsloading(true);
        try {
            await dispatch(actions.deleteRatio(deleteSelected.ratio_id))
        } catch (err) {
            console.log(err)
            setModalType("error");
            setModalShow(true);
        }

        setIsloading(false);

    }
    const editValueHandler = (event)=>{
        setUpdatedValue(event.target.value);
    }
    const confirmUpdate = async() =>{
        setModalShow(false);
        setIsloading(true);
        try {
            await dispatch(actions.updateRatio(selected.ratio_id, updatedValue));
        } catch (error) {
            console.log(error)
        }
        setIsloading(false);

    }
    
    return (
        <div className={classes.adminContainer}>
            <div className={classes.mainContainer}>
                <h1 className={classes.mainTitle}>Ratios</h1>
                <div className={classes.bottomControlsContainer}>
                    <div className={classes.inputContainer}>
                        <input placeholder='Name...' className={classes.inputElement} onChange={(event) => inputNameHandler(event)} value={name} />
                    </div>
                    
                    <div>
                        <Button btnType='Save' clicked={() => addPostHandler(name)} isDisabled={name.length === 0}>Create</Button>
                    </div>
                </div>
          
                <div className={classes.tableContainer}>
                    <table className={classes.table}>
                        <thead>
                            <tr>
                                <th>Name</th>

                                <th>Action</th>
                            </tr>
                        </thead>
                        {posts.length > 0 ? (

                            <tbody>
                                {posts.map(post => (
                                    <tr key={post.ratio_id}>
                                        <td>{post.name}</td>

                                        <td><Button btnType='Orange' clicked={() => {
                                            selectEditHandler(post);
                                        }}>Edit</Button>
                                            <Button btnType='Danger' clicked={() => {
                                                selectDeleteHandler(post);
                                            }}>Delete</Button></td>
                                    </tr>
                                ))}


                            </tbody>

                        ) : null}

                    </table>

                </div>
           
                {isLoading ? <div className={classes.loaderContainer}>
                    <Loader />
                </div> : null}

            </div>
            <Modal show={modalShow} modalClosed={modalHandler}>
                {modalType === 'edit' ? <div className={classes.modalContainer}>
                    <p className={classes.title}>Editing</p>
                    <input className={classes.inputElement} value={updatedValue} onChange={editValueHandler} />
                    <div className={classes.buttonContainer}>
                        <Button btnType='Soft' clicked={modalHandler}>Cancel</Button>

                        <Button btnType='Save' clicked={confirmUpdate} isDisabled={updatedValue.length <= 0}>Confirm</Button>

                    </div>
                </div> :
                    <div className={classes.modalContainer}>
                        <p className={classes.title}>Error</p>
                        <p className={classes.infoText}>Oops! The selected data can't be deleted</p>
                        <div className={classes.buttonContainer}>
                            <Button btnType='Orange' clicked={modalHandler}>Dismiss</Button>
                        </div>

                    </div>
                }
            </Modal>
            <ModalDelete show={modalDeleteShow} modalClosed={modalDeleteHandler} deleteAction={deleteItem} />
        </div>
    );
}

export default AdminRatiosScreen;