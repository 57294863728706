import React from 'react';

import classes from './Button.module.css';

const Button = props =>{
    let buttonSyle = [];
    if(props.isDisabled){
        buttonSyle =[classes.Button, classes.Disabled].join(' ');
    }else{
       buttonSyle =[classes.Button, classes[props.btnType]].join(' ');
    }
    return(
        <button onClick={props.clicked} className={buttonSyle} disabled={props.isDisabled}>{props.children}</button>
        );

}

export default Button;