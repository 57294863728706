import React from 'react';
import { NavLink } from 'react-router-dom';
import classes from './NavigationItem.module.css';

const NavigationItem = props => {
    return (
            <NavLink exact className={classes.Link} activeClassName={classes.LinkActive} to={props.path} >
                <div className={classes.Icon}>
                    <i className={props.iconName}></i>
                </div>
                <div className={classes.Title}>
                {props.title}
                </div>
                
            </NavLink>
        
    );
}

export default NavigationItem;



{/* <div className={classes.LinkWrapper}>
<div className={classes.Icon}>
    <i className={props.iconName}></i>
</div>
<NavLink  exact className={classes.Link} activeClassName={classes.LinkActive} to={props.path} >{props.title}</NavLink>
</div> */}